import {
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AsyncTaskStatus, BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import {
    ApplicationFiltersStore,
    SimpleTopicCluster,
} from "./ApplicationFiltersStore";
import { SignalsService } from "services/SignalsService";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { serializeToUtc } from "utils/DateTimeUtils";
import AgentService from "services/AgentService";
import { AuthStore } from "stores/AuthStore";
import Classifier from "models/ClassifierModel";
import ClassifierService from "services/ClassifierService";
import CustomerTypesService from "services/CustomerTypesService";
import type { MappedCustomerType } from "models/CustomerType";
import { contactTypes } from "./Filters/ContactTypes";
import { ModuleService } from "services/ModuleService";

@AcxStore
export class ApplicationFilterValuesStore extends BaseStore {
    public static Tasks = {
        LOAD_TOPICS: "Load Topics",
        LOAD_USER_CLASSIFIERS: "Load User Classifiers",
        LOAD_LICENSED_MODULES: "Load Licensed Modules",
        LOAD_MAPPED_CUSTOMER_TYPES: "Load Mapped Customer Types",
        GET_AGENT: "Get Agent",
        SEARCH_AGENTS: "Search Agents",
    } as const;

    private readonly applicationFiltersStore: ApplicationFiltersStore;
    private readonly messageStore: MessageStore;
    private readonly authStore: AuthStore;

    private readonly signalsService = new SignalsService();
    private readonly agentService = new AgentService();
    private readonly classifierService = new ClassifierService();
    private readonly customerTypesService = new CustomerTypesService();
    private readonly moduleService = new ModuleService();

    @observable
    topicClusters: SimpleTopicCluster[] | undefined;

    @observable
    userClassifiers: Classifier[] | undefined;

    @observable
    mappedCustomerTypes: MappedCustomerType[] = [];

    @computed get contactTypeOptions() {
        return contactTypes.map((contactType) => ({
            label: this.getContactTypeLabel(contactType),
            value: contactType,
        }));
    }

    @observable
    licensedModules: { id: string; title: string }[] | undefined;

    constructor(private rootStore: IRootStore) {
        super("Application Filter Values");
        makeObservable(this);

        this.applicationFiltersStore = rootStore.getStore(
            ApplicationFiltersStore,
        );
        this.messageStore = rootStore.getStore(MessageStore);
        this.authStore = rootStore.getStore(AuthStore);

        reaction(
            () => this.authStore.orgStore.selectedOrganization,
            () => {
                this.loadUserClassifiers();
                this.loadMappedCustomerTypes();
            },
            { fireImmediately: true },
        );
    }

    /**
     * Loads topic clusters if they have not already been loaded and they aren't already loading.
     * @returns
     */
    async loadTopics() {
        if (
            !!this.topicClusters ||
            this.getTaskLoading(ApplicationFilterValuesStore.Tasks.LOAD_TOPICS)
        )
            return;

        return this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.LOAD_TOPICS,
            async () => {
                try {
                    const topics = await this.signalsService.getTrendingTopics(
                        serializeToUtc(this.applicationFiltersStore.startDate)!,
                        serializeToUtc(this.applicationFiltersStore.endDate)!,
                    );
                    runInAction(() => {
                        this.topicClusters = topics;
                    });
                } catch (error) {
                    this.topicClusters = undefined;
                    this.messageStore.logError(
                        "Failed to retrieve topics. Please try again.",
                    );
                }
            },
        );
    }

    async loadUserClassifiers() {
        this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.LOAD_USER_CLASSIFIERS,
            async () => {
                try {
                    const classifiers =
                        await this.classifierService.getUserClassifiers(
                            this.authStore.orgStore.selectedOrganization?.id,
                            true,
                        );
                    runInAction(() => {
                        this.userClassifiers = classifiers;
                    });
                } catch (err: any) {
                    this.messageStore.logError(
                        "Failed to load classifiers: " + JSON.stringify(err),
                    );
                }
            },
        );
    }

    async loadMappedCustomerTypes() {
        this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.LOAD_MAPPED_CUSTOMER_TYPES,
            async () => {
                try {
                    const mappedCustomerTypes =
                        await this.customerTypesService.getMappedCustomerTypes();
                    runInAction(() => {
                        this.mappedCustomerTypes = mappedCustomerTypes;
                    });
                } catch (error) {
                    this.messageStore.logError(
                        "Failed to load contact type labels: " +
                            JSON.stringify(error),
                    );
                }
            },
        );
    }

    async loadModules() {
        if (
            !!this.licensedModules ||
            this.getTaskLoading(
                ApplicationFilterValuesStore.Tasks.LOAD_LICENSED_MODULES,
            )
        )
            return;

        return this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.LOAD_LICENSED_MODULES,
            async () => {
                try {
                    const modules =
                        await this.moduleService.getLicensedModules();

                    runInAction(() => {
                        this.licensedModules = modules;
                    });
                } catch (error) {
                    this.licensedModules = undefined;
                    this.messageStore.logError(
                        "Failed to retrieve modules. Please try again.",
                    );
                }
            },
        );
    }

    getContactTypeLabel(sourceContactType: string) {
        const customerType = this.mappedCustomerTypes.find(
            (c) => c.sourceName === sourceContactType,
        );

        if (!customerType) return sourceContactType;
        return customerType.name;
    }

    async searchAgent(inputValue: string) {
        const agents = await this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.SEARCH_AGENTS,
            async () => {
                try {
                    return await this.agentService.searchAgents(
                        this.authStore.orgStore.selectedOrganization?.id,
                        inputValue,
                    );
                } catch (error) {
                    this.messageStore.logError(
                        "Failed to search agents. Please try again.",
                    );
                    return [];
                }
            },
        );

        if (agents === AsyncTaskStatus.Error) return [];

        return agents;
    }

    async getAgent(agentId: string) {
        const agent = await this.setupAsyncTask(
            ApplicationFilterValuesStore.Tasks.GET_AGENT,
            async () => {
                try {
                    return await this.agentService.getAgent(agentId);
                } catch (error) {
                    this.messageStore.logError(
                        "Failed to load selected agent. Please try again.",
                    );
                    return undefined;
                }
            },
        );

        if (agent === AsyncTaskStatus.Error) return undefined;

        return agent;
    }
}

import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import Agentv2 from "models/Agentv2";
import { ISourceFile } from "models/AudioMetadataModel";
import { EvaluationStatus } from "models/Evaluation";
import { OrganizationStructureLevel } from "models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "models/OrganizationModels/OrganizationStructureMember";
import { User } from "oidc-client";
import React from "react";
import { AudioFilesService, SamplingType } from "services/AudioFilesService";
import { AudioSamplingService } from "services/AudioSamplingService";
import { Campaign, CampaignService } from "services/CampaignService";
import {
    InteractionRecord,
    InteractionService,
} from "services/InteractionService";
import { MetaLabelService } from "services/MetaLabelService";
import { OrganizationService } from "services/OrganizationService";
import QuestionMetadataService from "services/QuestionMetadataService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { toIsoByDateReference } from "utils/DateTimeUtils";
import { LayoutDrawerStore } from "../../../stores/Layout/LayoutDrawerStore";
import VerticalDrawerContentTemplate from "../../UI/Drawer/VerticalDrawerContentTemplate";
import {
    AnalystManagerNavigationStore,
    AnalystManagerPages,
} from "./AnalystManagerNavigationStore";
import AssignInteractionToHierarchyDialogStore from "./AssignInteractionToHierarchyDialogStore";
import MessageStore from "./MessageStore";

export const LOAD_ASSIGNED = "Load Assigned Interaction data.";
export const LOAD_UNASSIGNED = "Load Unassigned Interaction data.";
export const GENERATE_SAMPLE = "Generate data to sample.";
export const SELECT_SAMPLE = "Select data to sample.";

export enum SelectionOption {
    Org,
    MyTeam,
    Self,
    SpecificTeam,
}

export interface SummaryStats {
    total: number;
    statuses: Map<EvaluationStatus, number>;
}

export interface IExtendedMetadataFilterItem {
    item1: string;
    item2: number;
    value?: string;
}

@AcxStore
export default class ManagerInteractionsStore extends BaseStore {
    private readonly metaLabelService: MetaLabelService;
    private readonly orgService = new OrganizationService();
    private readonly interactionService = new InteractionService();
    private readonly audioSamplingService = new AudioSamplingService();
    private readonly audioFileService = new AudioFilesService();
    private readonly campaignService = new CampaignService();
    private readonly questionMetadataService = new QuestionMetadataService();

    readonly unassignAnalystDialog: DialogComponentStore<ManagerInteractionsStore>;
    readonly onDemandSampleDialog: DialogComponentStore<ManagerInteractionsStore>;
    readonly assignedDatePickerStore = new DatePickerComponentStore();
    readonly unassignedDatePickerStore = new DatePickerComponentStore();
    readonly interactionDgStore: AcxDataGridStore;
    readonly unassignedDgStore: AcxDataGridStore;
    readonly onDemandDgStore: AcxDataGridStore;

    authStore: AuthStore;
    // _evalStore: EvalStore;
    _dialogStore: AssignInteractionToHierarchyDialogStore;
    messageStore: MessageStore;
    drawerStore: LayoutDrawerStore;

    @observable.ref
    user: User | null;

    @observable.ref
    metaLabels?: IColDef[];

    @observable
    orgSelectStore = new OrgSelectorComponentStore((newOrg) => this.reset());

    @observable
    openAnalystReassign: boolean = false;

    @observable
    openUnAssign: boolean = false;

    @observable
    interactionSummary?: SummaryStats;

    @observable
    error = "";

    @observable
    hierarchyLevels: OrganizationStructureLevel[] = [];

    @observable
    campaigns: Campaign[] = [];

    @observable
    hierarchyMembers: OrganizationStructureMember[] = [];

    @observable
    selectedHierarchy?: { id: string; label: string };

    @observable
    analystGroups: OrganizationStructureMember[] = [];

    @observable
    teams: { label: string; id: string }[] = [];

    @observable
    selectedTeam?: { label: string; id: string };

    @observable
    selectionOption = SelectionOption.MyTeam;

    @observable
    _allowReassign = false;

    // ON-DEMAND SAMPLE OBSERVABLES
    @observable
    onDemandFailureAmdIds: string[] = [];

    @observable
    callDirection?: { label: string; value: string };

    @observable
    callLengthMin?: number;

    @observable
    callLengthMax?: number;

    @observable
    selectedMediaType?: { label: string; value: string };

    @observable
    selectedCampaign?: { label: string; value: string };

    @observable
    selectedAgent?: Agentv2;

    @observable
    metaLabelSelectLabels: any = {};

    @observable
    meta1?: string;

    @observable
    meta2?: string;

    @observable
    meta3?: string;

    @observable
    meta4?: string;

    @observable
    meta5?: string;

    @observable
    extendedMetadata?: IExtendedMetadataFilterItem[];

    @observable
    onDemandSamplingType?: SamplingType;

    @observable
    phone?: string;

    @observable
    shouldOverrideOSM?: boolean = false;

    @observable
    conversationMessageCountMax?: number;

    @observable
    conversationMessageCountMin?: number;

    @observable
    showMachineEvals?: boolean = false;

    constructor(public rootStore: IRootStore) {
        super("InteractionManagementStore");
        makeObservable(this);

        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);

        this.interactionDgStore = new AcxDataGridStore(
            "AssignedInteractionManager",
            "Interactions",
        );
        this.interactionDgStore.isLoading = true;

        this.unassignedDgStore = new AcxDataGridStore(
            "Un-AssignedInteractionManager",
            "Interactions",
        );
        this.unassignedDgStore.isLoading = true;

        this.onDemandDgStore = new AcxDataGridStore(
            "OnDemandSamplingManager",
            "Interactions",
        );

        this.unassignAnalystDialog = new DialogComponentStore(undefined, this);
        this.onDemandSampleDialog = new DialogComponentStore(undefined, this);

        this._dialogStore = rootStore.getStore(
            AssignInteractionToHierarchyDialogStore,
        );

        this.authStore = rootStore.getStore(AuthStore);

        this.messageStore = rootStore.getStore(MessageStore);

        this.metaLabelService = new MetaLabelService();

        this.authStore
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
            })
            .catch((reason) => {
                console.error(
                    `ManagerInteractionStore::failed to load userInfo ${reason}`,
                );
            });

        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
                user: this.user,
                activeLocation: this.rootStore.activeLocation,
            }),
            this.debounceEffect(async (params) => {
                if (
                    params.activeLocation &&
                    !params.activeLocation.location.includes("interactions")
                ) {
                    return;
                }

                this.setupAsyncTask(
                    "Load Analyst Group Assignments",
                    async () => {
                        if (!params.user || !params.orgId) {
                            return;
                        }

                        this.drawerStore.closeAndResetDrawer();

                        const t1 = this.getAnalystGroups(
                            params.user.profile.sub,
                        );

                        const t3 = this.getTeams(
                            params.orgId,
                            params.user.profile.sub,
                        );

                        await Promise.all([t1, t3]);
                    },
                );
            }, 255),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            this.debounceEffect(async (params) => {
                if (
                    params.activeLocation &&
                    !params.activeLocation.location.includes("On-Demand")
                ) {
                    return;
                }

                this.setupAsyncTask(
                    "Load Campaigns And Extended Metadata",
                    async () => {
                        if (!params.orgId) {
                            return;
                        }
                        const campaignRes =
                            await this.campaignService.getCampaigns();

                        if (campaignRes && campaignRes.length) {
                            this.campaigns = campaignRes;
                        } else {
                            this.campaigns = [];
                        }

                        const extendedMetadataRes =
                            await this.questionMetadataService.getMetadataFields();

                        if (extendedMetadataRes && extendedMetadataRes.length) {
                            this.extendedMetadata = extendedMetadataRes.filter(
                                (extMetaItem) => extMetaItem.item2 !== 0,
                            );
                        } else {
                            this.extendedMetadata = undefined;
                        }
                    },
                );
            }, 255),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
                user: this.user,
                beginDate: this.unassignedDatePickerStore.beginDate,
                endDate: this.unassignedDatePickerStore.endDate,
                referenceOption: this.unassignedDatePickerStore.referenceOption,
                activeLocation: this.rootStore.activeLocation,
            }),
            this.debounceEffect(async (params) => {
                this.unassignedDgStore.setLoading(true);

                if (
                    params.activeLocation &&
                    !params.activeLocation.location.includes(
                        "interactions/Unassigned",
                    )
                )
                    return;

                if (!params.user || !params.orgId) {
                    return;
                }

                this._dialogStore.setOrgId(params.orgId);

                this.setupAsyncTask(LOAD_UNASSIGNED, async () => {
                    if (!params.user || !params.orgId) {
                        return;
                    }

                    await this.getUnassignedInteractions();
                });
            }, 555),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
                selectionOption: this.selectionOption,
                selectedGroup: this.selectedTeam,
                activeLocation: this.rootStore.activeLocation,
                showMachineEvals: this.showMachineEvals,
            }),
            this.debounceEffect(async (params) => {
                if (
                    (params.activeLocation &&
                        !params.activeLocation.location.includes(
                            "interactions/Assigned",
                        )) ||
                    params.orgId === undefined ||
                    params.selectionOption === undefined ||
                    (params.selectionOption === SelectionOption.SpecificTeam &&
                        params.selectedGroup === undefined)
                ) {
                    return;
                }

                const metaLabels = await this.getMetaLabels();

                if (metaLabels && Object.keys(metaLabels).length > 0) {
                    const formattedMetaLabels: IColDef[] = [];

                    for (const [key, value] of Object.entries(metaLabels)) {
                        formattedMetaLabels.push({
                            headerName: value as string,
                            field: key.toString().toLowerCase(),
                            type: "string",
                            width: 110,
                            hide: false,
                        });
                    }

                    this.setMetaLabels(formattedMetaLabels);
                } else {
                    this.setMetaLabels(undefined);
                }

                if (
                    (params.selectionOption === SelectionOption.Org ||
                        params.selectionOption === SelectionOption.MyTeam) &&
                    this.interactionDgStore.rows.length < 0
                ) {
                    this.messageStore.logMessage(
                        "To load the table, please select a desired Date Range and Date Type and then press refresh.",
                        "info",
                    );

                    this.interactionSummary = undefined;
                    this.interactionDgStore.reset();

                    return;
                }

                await this.getAssignedInteractions(
                    params.selectionOption,
                    params.selectedGroup?.id,
                );
            }, 555),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            () => this.anyTaskLoading,
            () => {
                if (this.getTaskLoading(LOAD_UNASSIGNED)) {
                    this.unassignedDgStore.isLoading = true;
                } else {
                    this.unassignedDgStore.isLoading = false;
                }
            },
        );

        // ON-DEMAND SAMPLE REACTIONS
        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            ({ orgId, activeLocation }) => {
                if (orgId && activeLocation.location.includes("On-Demand")) {
                    this.messageStore.logInfo(
                        "Please Define and Apply Filters to generate an On-Demand Sample.",
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.orgSelectStore.orgId,
            (orgId) => {
                if (orgId) {
                    this.onDemandDgStore.rows = [];
                    this.onDemandDgStore.clearSelected();
                    this.resetFilters();
                    this.metaLabelSelectLabels = {};
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.onDemandDgStore.selectedRows.length,
            (rowLength) => {
                if (rowLength > 10) {
                    this.messageStore.logInfo(
                        "Sample size is limited to 10 files.",
                    );
                }
            },
            { fireImmediately: true },
        );
    }

    // ON-DEMAND SAMPLE ACTIONS
    @action
    setCallLengthMin = (minLength: string) => {
        let value = parseFloat(minLength);
        if (value < 0) value = 0;

        this.callLengthMin = value;
    };

    @action
    setCallLengthMax = (maxLength: string) => {
        let value = parseFloat(maxLength);
        if (value < 0) value = 0;

        this.callLengthMax = value;
    };

    @action
    setCallDirection = (callDirection) => {
        this.callDirection = callDirection;
    };

    @action
    setSelectedMediaType = (mediaType) => {
        this.selectedMediaType = mediaType;
    };

    @action
    setSelectedCampaign = (campaignSelection: {
        label: string;
        value: string;
    }) => {
        this.selectedCampaign = campaignSelection;
    };

    @action
    setSelectedAgent = (agent: Agentv2) => {
        this.selectedAgent = agent;
    };

    @action
    setMeta1 = (data: string) => {
        this.meta1 = data;
    };

    @action
    setMeta2 = (data: string) => {
        this.meta2 = data;
    };

    @action
    setMeta3 = (data: string) => {
        this.meta3 = data;
    };

    @action
    setMeta4 = (data: string) => {
        this.meta4 = data;
    };

    @action
    setMeta5 = (data: string) => {
        this.meta5 = data;
    };

    @action
    setPhone = (dater: string) => {
        this.phone = dater;
    };

    @action
    setExtendedMetadata = (index: number, dater: string) => {
        if (this.extendedMetadata) {
            this.extendedMetadata[index].value = dater;
        }
    };

    @action
    toggleShouldOverrideOSM = () => {
        this.shouldOverrideOSM = !this.shouldOverrideOSM;
    };

    @action
    setconversationMessageCountMax = (dater: string) => {
        this.conversationMessageCountMax = Number(dater);
    };

    @action
    setconversationMessageCountMin = (dater: string) => {
        this.conversationMessageCountMin = Number(dater);
    };

    @action
    resetFilters = () => {
        runInAction(() => {
            this.callLengthMax = undefined;
            this.callLengthMin = undefined;
            this.callDirection = undefined;
            this.selectedMediaType = undefined;
            this.selectedAgent = undefined;
            this.meta1 = undefined;
            this.meta2 = undefined;
            this.meta3 = undefined;
            this.meta4 = undefined;
            this.meta5 = undefined;
            this.phone = undefined;
            this.selectedCampaign = undefined;
            this.conversationMessageCountMax = undefined;
            this.conversationMessageCountMin = undefined;
            this.extendedMetadata?.forEach((item, index) =>
                this.setExtendedMetadata(index, ""),
            );
        });
    };

    @action
    generateSample = (
        displayFailures: boolean = false,
        updateFailedRows?: () => void,
    ) => {
        this.setupAsyncTask(GENERATE_SAMPLE, async () => {
            this.onDemandDgStore.setLoading(true);

            const sampleData =
                await this.audioSamplingService.generateOnDemandSample(
                    this.assignedDatePickerStore.beginDate,
                    this.assignedDatePickerStore.endDate,
                    this.orgSelectStore.orgId ?? "",
                    this.callDirection?.value,
                    this.selectedAgent?.id?.toString(),
                    this.selectedMediaType?.value,
                    this.callLengthMin,
                    this.callLengthMax,
                    this.meta1,
                    this.meta2,
                    this.meta3,
                    this.meta4,
                    this.meta5,
                    this.phone,
                    this.selectedCampaign?.value,
                    this.extendedMetadata,
                    this.conversationMessageCountMax,
                    this.conversationMessageCountMin,
                );

            this.onDemandDgStore.reset();
            this.onDemandDgStore.rows = sampleData.map((value) => value.item1);
            this.onDemandDgStore.setLoading(false);

            if (displayFailures && this.onDemandFailureAmdIds.length > 0) {
                this.onDemandDgStore.handleSelectionChange(
                    this.onDemandFailureAmdIds,
                );
                this.onDemandFailureAmdIds = [];

                if (updateFailedRows) updateFailedRows();
            }
        });
    };

    private transformRowsToFiles = (rows: any[]): ISourceFile[] => {
        const sourceFiles: ISourceFile[] = rows.map((row) => {
            return {
                id: row.id,
                directoryId: row.directoryId,
                sourceFolder: row.origDirectoryPath,
                fileName: row.fileName,
            };
        });

        return toJS(sourceFiles);
    };

    @action
    selectOnDemandSample = () => {
        this.setupAsyncTask(SELECT_SAMPLE, async () => {
            if (
                this.onDemandDgStore.selectedRows &&
                this.orgSelectStore.orgId
            ) {
                try {
                    const sourceFiles = this.transformRowsToFiles(
                        this.onDemandDgStore.selectedRows,
                    );

                    const response =
                        await this.audioFileService.selectOnDemandSample(
                            this.orgSelectStore.orgId,
                            this.selectedHierarchy?.id,
                            sourceFiles,
                            this.onDemandSamplingType,
                            this.shouldOverrideOSM,
                        );

                    let failures: string[] = [];
                    let failedRows: any[] = [];
                    for (let i = 0; i < response.length; i++) {
                        const info = response[i];

                        if (info.result === "Fail") {
                            failures.push(info.amdId);
                            failedRows.push(info);
                        }
                    }

                    if (failures.length > 0) {
                        this.onDemandFailureAmdIds = failures;

                        this.messageStore.logError(
                            `${failures.length} File${
                                failures.length > 1 ? "s" : ""
                            } are ineligible for sampling and have been highlighted. If applicable, all other files have been sent to process and can be checked under My Evaluations.`,
                        );
                    } else {
                        this.onDemandFailureAmdIds = [];

                        this.messageStore.logMessage(
                            `File${
                                sourceFiles.length > 1 ? "s are now" : " is now"
                            } being processed. File processing status can be observed under My Evaluations.`,
                            "success",
                        );
                    }

                    this.generateSample(true, () => {
                        for (const failedRow of failedRows) {
                            const foundIndex =
                                this.onDemandDgStore.rows.findIndex(
                                    (rowItem) => rowItem.id === failedRow.amdId,
                                );

                            if (foundIndex !== -1)
                                this.onDemandDgStore.rows[
                                    foundIndex
                                ].sampleResponse = failedRow.message;
                        }
                    });
                } catch (error) {
                    this.messageStore.logError((error as any).message);
                }
            }
        });
    };

    // BEGIN ASSIGN & UNASSIGNED
    @action
    setMetaLabels = (labels?: IColDef[]) => {
        this.metaLabels = labels;
    };

    @action
    reset = () => {
        this.unassignedDgStore.reset();
        this.interactionDgStore.reset();

        this.selectedTeam = undefined;
        this.interactionSummary = undefined;
    };

    @action
    setSelectionOption = (newOption?: SelectionOption) => {
        this.onHideQueues();
        this.selectionOption = newOption ?? SelectionOption.MyTeam;
    };

    @action
    getUnassignedInteractions = async () => {
        const dates = toIsoByDateReference(
            this.unassignedDatePickerStore.beginDate,
            this.unassignedDatePickerStore.endDate,
            this.unassignedDatePickerStore.referenceOption,
        );

        const interactions =
            await this.interactionService.getUnassignedInteractions(
                dates.beginDate as string,
                dates.endDate as string,
                this.unassignedDatePickerStore.referenceOption,
                "InteractionManagerStore/unassigned-interactions",
            );
        this.unassignedDgStore.setLoading(false);
        runInAction(() => {
            this.unassignedDgStore.rows = interactions;
        });
    };

    @action
    getAssignedInteractions = async (
        selectionOption: SelectionOption,
        analystGroupId: string | undefined,
    ) => {
        this.setupAsyncTask(LOAD_ASSIGNED, async () => {
            this.interactionDgStore.setLoading(true);
            const dates = toIsoByDateReference(
                this.assignedDatePickerStore.beginDate,
                this.assignedDatePickerStore.endDate,
                this.assignedDatePickerStore.referenceOption,
            );

            const interactions =
                await this.interactionService.getAssignedInteractions(
                    selectionOption,
                    analystGroupId,
                    dates.beginDate as string,
                    dates.endDate as string,
                    this.assignedDatePickerStore.referenceOption,
                    "InteractionManagerStore/assigned-interactions",
                    this.orgSelectStore.organization?.enhancedEvalStatus,
                    this.showMachineEvals,
                );

            runInAction(() => {
                this.interactionDgStore.rows = interactions.data;

                const statusObj = interactions.summary.statuses as {
                    [key in EvaluationStatus]: number;
                };

                this.interactionSummary = {
                    total: interactions.summary.total,
                    statuses: new Map<EvaluationStatus, number>(
                        Object.entries(statusObj) as any,
                    ),
                };
                this.interactionDgStore.setLoading(false);
            });
        });
    };

    @action
    setOpenAnalystReassign = (opened: boolean): void => {
        this.openAnalystReassign = opened;
    };

    @action
    getAnalystGroups = async (analystId: string) => {
        const groups = await this.orgService.getAnalystGroups(analystId);
        const allowReassign = false;

        runInAction(() => {
            this.analystGroups = groups;
            this._allowReassign = allowReassign;
        });
    };

    @action
    getTeams = async (orgId: string, analystId: string) => {
        const res = await this.orgService.getFlattenedServiceHierarchyForUser(
            orgId,
            analystId,
        );

        runInAction(() => {
            this.teams = res;
        });
    };

    @action
    setSelectedTeam = (newTeam: { label: string; id: string }) => {
        this.onHideQueues();
        this.selectedTeam = newTeam;
    };

    @action
    openDialog = () => {
        this._dialogStore.dialogStore.open();
    };

    @action
    closeDialog = () => {
        this._dialogStore.dialogStore.close();
    };

    @action
    handleAssignConfirmClick = () => {};

    @action
    onHideQueues = () => {
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
    };

    @action
    onViewQueues = (content: React.ReactNode, mainContentClass?: string) => {
        this.drawerStore.closeAndResetDrawer();
        this.drawerStore.restoreDefaults();
        this.drawerStore.offsetPx = 0;

        const title = "My Service Hierarchies";

        this.drawerStore.setContentFactory(() => (
            <VerticalDrawerContentTemplate
                width={"450px"}
                title={title}
                content={content}
                mainContentClass={mainContentClass}
                titleStyles={{ padding: "0.75rem" }}
                contentStyles={{ padding: "0.75rem", height: "85%" }}
            />
        ));

        this.drawerStore.openDrawer();
    };

    @action
    assignInteractions = async () => {
        const hierarchySelections = this._dialogStore.hierarchySelections;
        const currentPage = this.rootStore.getStore(
            AnalystManagerNavigationStore,
        ).currentPage;

        if (hierarchySelections.length < 1) {
            return;
        }

        if (!this.orgSelectStore.orgId) {
            return;
        }

        let interactionIds: string[] = [];
        if (currentPage === AnalystManagerPages.UNASSIGNED) {
            const amdIds = this.unassignedDgStore.selectedRowIds.map((val) =>
                val.toString(),
            );

            interactionIds = this.unassignedDgStore.rows
                .filter((value) => amdIds.includes(value["id"]))
                .map((value) => value?.["interaction"]?.["id"])
                .filter((value) => !!value);
        } else if (currentPage === AnalystManagerPages.ASSIGNED) {
            interactionIds = this.interactionDgStore.selectedRowIds.map((val) =>
                val.toString(),
            );
        }

        const memberId = hierarchySelections[hierarchySelections.length - 1].id;
        if (!interactionIds || !memberId) {
            return;
        }

        this._dialogStore.dialogStore.setLoading();

        try {
            await this.interactionService.assignInteractions(
                interactionIds,
                memberId,
            );

            await this.reloadInteractions();

            this._dialogStore.dialogStore.setNotLoading();

            this._dialogStore.dialogStore.close();
        } catch (e) {
            console.error(e);
            this._dialogStore.dialogStore.setNotLoading();
            this._dialogStore.dialogStore.setErrorMessage(
                (e as any).errorMessage,
            );
        }
    };

    @action
    assignToAnalyst = async (analystId) => {
        const currentPage = this.rootStore.getStore(
            AnalystManagerNavigationStore,
        ).currentPage;

        let evalIds: string[] = [];
        if (currentPage === AnalystManagerPages.ASSIGNED) {
            evalIds = this.interactionDgStore
                .getSelectedRows()
                .map((val) => val.evaluationId);
        }

        this.setupAsyncTask("Assign To Analyst", async () => {
            await this.interactionService.assignToAnalyst(evalIds, analystId);
            await this.reloadInteractions();
        });
    };

    @action
    unAssignAnalyst = async () => {
        const currentPage = this.rootStore.getStore(
            AnalystManagerNavigationStore,
        ).currentPage;

        let evalIds: string[] = [];
        if (currentPage === AnalystManagerPages.ASSIGNED) {
            this.interactionDgStore.getSelectedRows().forEach((val) => {
                const ir = val as InteractionRecord;

                if (ir.evaluationStatus !== EvaluationStatus.Completed) {
                    evalIds.push(ir.evaluationId);
                }
            });
        }

        this.setupAsyncTask("Unassign Analyst", async () => {
            await this.interactionService.unAssignAnalyst(evalIds);
            await this.reloadInteractions();
        });
    };

    @action
    unAssignInteractions = async () => {
        if (!this.orgSelectStore.orgId) {
            return;
        }

        try {
            const interactionIds = this.interactionDgStore.selectedRowIds;

            await this.interactionService.unAssignInteractions(
                interactionIds,
                null,
            );

            await this.reloadInteractions("Unassigned");

            this._dialogStore.dialogStore.setNotLoading();
        } catch (e) {
            this.error = e as any;
        }
    };

    @action
    reloadInteractions = async (
        action: "Assigned" | "Unassigned" = "Assigned",
    ) => {
        const params = {
            user: this.user,
            orgId: this.orgSelectStore.orgId,
            selectionOption: this.selectionOption,
            selectedTeam: this.selectedTeam,
        };

        if (action === "Assigned") {
            this.interactionDgStore.clearSelected();

            if (
                params.orgId === undefined ||
                params.selectionOption === undefined
            ) {
                return;
            }

            if (
                params.selectionOption === SelectionOption.SpecificTeam &&
                params.selectedTeam === undefined
            ) {
                return;
            }

            await this.getAssignedInteractions(
                params.selectionOption,
                params.selectedTeam?.id,
            );
        } else {
            this.unassignedDgStore.clearSelected();

            this.setupAsyncTask(LOAD_UNASSIGNED, async () => {
                this.unassignedDgStore.setLoading(true);
                if (!params.user || !params.orgId) {
                    return;
                }

                await this.getUnassignedInteractions();
            });
        }
    };

    @action
    setSelectedHierarchy = (hierarchy) => {
        this.selectedHierarchy = hierarchy;
    };

    @computed
    get allowReassign() {
        return this.authStore.canUserEdit("Interactions");
    }

    @computed
    get loading() {
        return this.anyTaskLoading;
    }

    @computed
    get isLoadingQueuesForTeam() {
        return this.getTaskLoading("GroupAssignedForTeam");
    }

    @computed
    get summaryStats(): SummaryStats {
        const stats = {
            total: 0,
            statuses: new Map<EvaluationStatus, number>(),
        };

        const isFiltered = false;
        // const isFiltered = this.interactionDgStore.filtered;
        if (!isFiltered && this.interactionSummary) {
            return this.interactionSummary;
        }

        for (const item of this.interactionDgStore.rows) {
            stats.total += 1;

            let statusTotal = stats.statuses.get(item.evaluationStatus) ?? 0;
            statusTotal += 1;

            stats.statuses.set(item.evaluationStatus, statusTotal);
        }

        return stats;
    }

    public async getMetaLabels() {
        const metaLabels = await this.metaLabelService.getMetaLabels();
        return metaLabels;
    }

    @action
    setSamplingType(samplingType: SamplingType) {
        this.onDemandSamplingType = samplingType;
    }

    @action
    toggleShowMachineEvals = () => {
        this.showMachineEvals = !this.showMachineEvals;
    };
}

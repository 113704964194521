import { Module } from "components/Admin/Organizations/types/Module.type";
import {
    AISummaryResult,
    IDashboardVisibility,
    ISignalsChartResponse,
    ISignalsDrilldownResponse,
    ISignalsUserDashboard,
    ISignalsVizDefinition,
    SignalDataEndpoint,
    SignalsFilters,
    SignalsRequest,
    SignalsStatAvgCallDurationRequest,
    SignalsTopics,
} from "components/Signals/Models/SignalsModels";
import { FeedbackType } from "components/Signals/Store/AISummariesStore";
import BaseService from "./BaseService";

export class SignalsService extends BaseService {
    protected urlBase = "api/signals";

    private abortSignal?: AbortSignal;

    /**
     * Sets the abort signal that will be used to cancel all report and stat requests
     * that are currently active
     * @param abortSignal
     */
    public setAbortSignal(abortSignal?: AbortSignal) {
        this.abortSignal = abortSignal;
    }

    public async refreshAISummary(
        dataEndpoint: SignalDataEndpoint,
        chartState: SignalsRequest,
    ) {
        const params = new URLSearchParams();
        const apiPath = `${dataEndpoint}/aisummary`;

        return this.post<AISummaryResult>(
            JSON.stringify(chartState),
            params,
            apiPath,
        );
    }

    public async aiSummaryFeedback(
        protectedDataBase64: string,
        feedbackType: FeedbackType,
        feedback: string | null = null,
    ) {
        const params = new URLSearchParams();
        const apiPath = "AISummaryFeedback";

        return this.post<void>(
            JSON.stringify({
                protectedDataBase64,
                feedbackType,
                feedback,
            }),
            params,
            apiPath,
        );
    }

    public async getChart(dataEndpoint: string, chartState: SignalsRequest) {
        const params = new URLSearchParams();
        const apiPath = dataEndpoint;

        return this.post<ISignalsChartResponse>(
            JSON.stringify(chartState),
            params,
            apiPath,
            undefined,
            undefined,
            this.abortSignal,
        );
    }

    public async getDrillDownForChart(
        dataEndpoint: SignalDataEndpoint,
        chartState: SignalsRequest,
        abortSignal?: AbortSignal,
    ) {
        const params = new URLSearchParams();
        const apiPath = `${dataEndpoint}/drilldown`;

        return this.post<ISignalsDrilldownResponse>(
            JSON.stringify(chartState),
            params,
            apiPath,
            undefined,
            undefined,
            abortSignal,
        );
    }

    public async getTotalConversations(request: SignalsFilters) {
        const params = new URLSearchParams();
        const apiPath = "TotalConversations";

        return this.post<{ totalConversations: number }>(
            JSON.stringify(request),
            params,
            apiPath,
            undefined,
            undefined,
            this.abortSignal,
        );
    }

    public async getAvgCallDuration(
        request: SignalsStatAvgCallDurationRequest,
    ) {
        const params = new URLSearchParams();
        const apiPath = "AvgCallDuration";

        return this.post<{ averageCallDuration: number }>(
            JSON.stringify(request),
            params,
            apiPath,
            undefined,
            undefined,
            this.abortSignal,
        );
    }

    public async getLicensedModulesWithScoredQuestions() {
        const apiPath = "GetLicensedModulesWithScoredQuestions";
        return this.get<Module[]>(new URLSearchParams({}), apiPath);
    }

    public async getTopics() {
        const apiPath = "topics";
        return this.get<SignalsTopics[]>(new URLSearchParams(), apiPath);
    }

    public async getTrendingTopics(startDate: string, endDate: string) {
        const apiPath = "topics/trending";
        return this.get<SignalsTopics[]>(
            new URLSearchParams({
                startDate,
                endDate,
            }),
            apiPath,
        );
    }

    // public async getSignalDashboardDefinitions() {
    //     const apiPath = "definitions";
    //     return this.get<ISignalChartRenderData[]>(
    //         new URLSearchParams({}),
    //         apiPath,
    //     );
    // }
    public async getUserDashboards(): Promise<ISignalsUserDashboard[]> {
        const apiPath = "GetUserDashboards";
        return this.get<ISignalsUserDashboard[]>(
            new URLSearchParams({}),
            apiPath,
        );
    }
    public async createDashboard(request: {
        title: string;
        visibility: IDashboardVisibility;
        logoName?: string;
    }) {
        const apiPath = "CreateDashboard";
        return this.post<ISignalsUserDashboard>(
            JSON.stringify(request),
            new URLSearchParams(),
            apiPath,
        );
    }
    public async updateDashboard(request: {
        id: string;
        title: string;
        visibility: IDashboardVisibility;
        logoName?: string;
        pinnedApplicationFilterId?: string;
    }) {
        const apiPath = "UpdateDashboard";
        return this.put<ISignalsUserDashboard>(
            new URLSearchParams(),
            apiPath,
            JSON.stringify(request),
        );
    }
    public async deleteDashboard(request: { id: string }) {
        const apiPath = "DeleteDashboard";
        return this.delete(new URLSearchParams(request), apiPath);
    }

    public async createVizDefinition(request: {
        dashboardId: string;
        order: string;
        dataEndpoint: SignalDataEndpoint;
        chartTitle: string;
        chartSubtitle: string;
        xTitle: string;
        yTitle: string;
        vizOptions: string;
    }) {
        const apiPath = "CreateVizDefinition";
        request.order = request.order.toString(); // TODO: check type ehre
        return this.post<any[]>(
            JSON.stringify(request),
            new URLSearchParams({}),
            apiPath,
        );
    }
    public async updateVizDefinition(request: {
        id: string;
        dashboardId: string;
        order?: number;
        dataEndpoint?: string;
        chartTitle?: string;
        chartSubtitle?: string;
        xTitle?: string;
        yTitle?: string;
        vizOptions?: string;
    }) {
        const apiPath = "UpdateVizDefinition";

        const body: string = JSON.stringify(request);
        return this.put<ISignalsVizDefinition>(
            new URLSearchParams(),
            apiPath,
            body,
        );
    }
    public async deleteVizDefinition(request: { id: string }): Promise<any> {
        const apiPath = "DeleteVizDefinition";

        return this.delete(new URLSearchParams(request), apiPath);
    }

    public async addVizDefinitionsToDashboard(
        dashboardId: string,
        vizDefinitions: ISignalsVizDefinition[],
    ) {
        const apiPath = "AddVizDefinitionsToDashboard";

        // need to remove extra fields because stringify is failing for larger dataStructures when adding many vizDefs
        const formattedDefs = vizDefinitions.map((v) => {
            return {
                order: v.order,
                dataEndpoint: v.dataEndpoint,
                chartTitle: v.chartTitle,
                chartSubtitle: v.chartSubtitle,
                xTitle: v.xTitle,
                yTitle: v.yTitle,
                vizOptions: v.vizOptions,
            };
        });

        const body: string = JSON.stringify(formattedDefs);

        return this.post<ISignalsVizDefinition[]>(
            body,
            new URLSearchParams({ dashboardId }),
            apiPath,
        );
    }

    public async saveLogo(
        uploadedFile: File,
        dashboardId: string,
    ): Promise<string> {
        const formData = new FormData();
        formData.append("uploadedFile", uploadedFile);

        return await this.post(
            formData,
            new URLSearchParams({
                logoName: uploadedFile.name,
                signalsDashboardId: dashboardId,
            }),
            "SaveLogoFile",
        );
    }

    public async deleteLogo(fileName: string, dashboardId: string) {
        await this.delete(
            new URLSearchParams({ fileName, dashboardId }),
            "DeleteLogo",
        );
    }

    public async getLogo(dashboardId: string) {
        return await this.get(new URLSearchParams({ dashboardId }), "GetLogo");
    }

    public async getLogos() {
        return await this.get(new URLSearchParams({}), "GetLogoList");
    }
}

import type { GridSize, GridProps } from "@mui/material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { GridRowParams } from "@mui/x-data-grid-pro";
import type {
    GridApiPro,
    GridColDef,
    GridFilterModel,
    GridRowId,
    GridRowModel,
    GridSortModel,
    DataGridProProps,
    GridColumnVisibilityModel,
    GridPaginationModel,
    GridCallbackDetails,
} from "@mui/x-data-grid-pro";
import { isString } from "lodash";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import Papa from "papaparse";
import React from "react";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { StyledMenuLabel } from "../Menu/AcxMenu";
import type { AcxMenuItemProps } from "../Menu/AcxMenu";
import IColDef from "./IColDef";
import _ from "lodash";
import {
    getPercentFilterOperators,
    PercentComparator,
    PercentFormatter,
} from "./Formatters/PercentFormatter";
import { ProgressFormatter } from "./Formatters/AcxProgressFormatter";
import { ChipComparator, TagFormatter } from "./Formatters/TagFormatter";
import { GenericLinkFormatter } from "./Formatters/GenericLinkFormatter";
import { LinkFormatter } from "./Formatters/LinkFormatter";
import {
    ArrayJoinComparator,
    ArrayJoinFormatter,
} from "./Formatters/ArrayJoinFormatter";
import {
    DateComparator,
    DateFormatterIgnoreTime,
    DateFormatterIgnoreTimeGetter,
} from "./Formatters/DateFormatters";
import { symmetricDifferenceBy } from "utils/SetAlgebraUtils";

export interface SelectionContextOptions {
    itemName?: string;
    itemNamePluralized?: string;
    renderSelectedRow: (row: GridRowModel) => React.ReactNode;
    action?: React.ReactNode;
}

export interface CustomControlItem {
    controlElement: React.ReactElement;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
    style?: React.CSSProperties;
}

export interface UnparseObject {
    data: [];
    fields: [];
}

const CacheKeyPrefix = "AcxDataGrid-v7";

class AcxDataGridStore {
    @observable.ref
    controlsColumnStyle?: React.CSSProperties;

    @observable.ref
    controlMargin?: {
        marginTop?: string;
        marginRight?: string;
        marginBottom?: string;
        marginLeft?: string;
    };

    @observable.ref vertIconMenuItemsBuilder?:
        | AcxMenuItemProps[]
        | ((close?: () => void, navigate?) => AcxMenuItemProps[]);

    // Vertical menu vars
    @observable.ref
    vertIconMenuItems?: AcxMenuItemProps[];

    @observable
    columns: IColDef[] = [];

    @observable
    menuAnchor: null | HTMLElement = null;

    @observable
    headerColumnSpan?: GridSize;

    @observable
    controlsColumnSpan?: GridSize;

    @observable
    controlsJustifyProperty?: GridProps["justifyContent"];

    @observable
    controlsAlignProperty?: GridProps["alignItems"];

    @observable
    headersJustifyProperty?: GridProps["justifyContent"];

    gridApi?: React.MutableRefObject<GridApiPro>;

    // Grid formatting options
    @observable
    removeHeight: string = "70px";

    @observable
    density: "compact" | "standard" | "comfortable";

    @observable
    checkboxSelection: boolean;

    @observable
    onRowCountChange?: DataGridProProps["onRowCountChange"];

    @observable
    checkboxSelectionVisibleOnly: boolean;

    @observable
    isLoading: boolean;

    @observable
    appDomain: string = "AcxGridDefault";

    @observable
    gridId: string = "collumns";

    @observable
    rows: GridRowModel[] = observable.array<GridRowModel>([]);

    @observable
    rowIds = observable<GridRowId>([]);

    @observable
    selectedRowIds = observable<GridRowId>([]);

    @observable
    selectedRows = observable<GridRowModel>([]);

    @observable
    filterColumn?: string | undefined;

    @observable
    filterIsOpen: boolean = false;

    @observable
    hideColumnsIsOpen: boolean = false;

    @observable
    hideFilter: boolean = false;

    @observable
    hideVertIcon: boolean = false;

    //props for controls and selection context expand box
    rowTerm?: React.ReactNode;
    title?: string;
    controls?: (React.ReactElement | CustomControlItem)[];
    selectionContextOptions?: SelectionContextOptions;
    preHeader?: React.ReactElement;

    disableLocalConfig = false;

    localStorage: LocalForage;

    disableMultipleColumnFiltering: boolean = true;

    @observable
    paginationSize: number = 50;

    @observable
    pagination?: boolean = false;

    @observable
    filterModel?: GridFilterModel;

    paginationMode?: "client" | "server";

    @observable
    paginationModel: GridPaginationModel = {
        pageSize: 10,
        page: 0,
    };

    @observable
    pageSizeOptions: (number | { label: string; value: number })[] = [10];

    @observable
    rowCount?: number;

    filterMode?: "client" | "server";

    sortingMode?: "client" | "server";

    sortRequest?: (input: GridSortModel) => any;

    onPaginationModelChange?:
        | ((model: GridPaginationModel, details: GridCallbackDetails) => void)
        | undefined;

    @observable
    isRowSelectable?:
        | ((params: GridRowParams, details?: any) => boolean)
        | undefined;

    @observable
    onChange?: () => Promise<any>;

    public constructor(gridId?: string, appDomain?: string) {
        makeObservable(this);

        if (gridId) {
            this.gridId = gridId;
        }

        if (appDomain) {
            this.appDomain = appDomain;
        }

        this.density = "standard";
        this.checkboxSelection = true;
        this.isLoading = false;

        const local = RootStore().getStore(LocalStorage);
        this.localStorage = local.getLocalStore(this.appDomain);

        reaction(
            (r) => this.rows,
            (arg) => {
                this.rowIds.replace(
                    this.rows.map((row) => row.id) as GridRowId[],
                );
            },
        );
    }

    @action
    setColumns = async (columns: IColDef[], useCustomRenderTypes?: boolean) => {
        if (useCustomRenderTypes) {
            columns.forEach((curCol) => {
                if (typeof curCol.renderType === "string") {
                    if (curCol?.renderType === "arrayJoinFormatter") {
                        curCol.renderCell = ArrayJoinFormatter;
                        curCol.sortComparator = ArrayJoinComparator;
                    }
                    if (curCol?.renderType === "dateFormatterIgnoreTime") {
                        curCol.renderCell = DateFormatterIgnoreTime;
                        curCol.valueGetter = DateFormatterIgnoreTimeGetter;
                        curCol.sortComparator = DateComparator;
                    }
                    return;
                }

                if (curCol.renderType?.type === "percentFormatter") {
                    curCol.sortComparator = PercentComparator;
                    curCol.renderCell = PercentFormatter;
                    if (curCol.renderType.filterFields)
                        curCol.filterOperators = getPercentFilterOperators(
                            curCol.renderType.filterFields,
                        );
                } else if (curCol?.renderType?.type === "progressFormatter") {
                    curCol.renderCell = ProgressFormatter(
                        curCol.renderType.fields,
                    );
                    curCol.filterable = false;
                    curCol.sortable = false;
                } else if (curCol?.renderType?.type === "chip") {
                    curCol.renderCell = TagFormatter(curCol.renderType.fields);
                    curCol.sortComparator = ChipComparator;
                } else if (
                    curCol?.renderType?.type === "genericLinkFormatter"
                ) {
                    curCol.renderCell = GenericLinkFormatter(
                        curCol.renderType.fields,
                    );
                } else if (curCol?.renderType?.type === "linkFormatter") {
                    curCol.renderCell = LinkFormatter(curCol.renderType.fields);
                }
            });
        }

        if (this.disableLocalConfig) {
            this.columns = columns;
        } else {
            this.columns = await this.mergeColsWithCache(columns);
        }
    };

    @action
    setHideVertIcon = (isHidden: boolean) => {
        this.hideVertIcon = isHidden;
    };

    @action
    public buildVertMenuOptionForCsvExport(enable: boolean): AcxMenuItemProps {
        const exportToCsvButton: AcxMenuItemProps = {
            id: `export-to-csv-button`,
            label: <StyledMenuLabel>Export to CSV</StyledMenuLabel>,
            icon: <SubdirectoryArrowRightIcon />,
            props: {
                disabled: !enable,
                onClick: () => {
                    let parsedData = {} as any;

                    this.rows.forEach((row, index) => {
                        if (index < 1) {
                            parsedData["fields"] = Object.keys(row);
                            parsedData["data"] = [Object.values(row)];
                        } else {
                            parsedData["data"].push(Object.values(row));
                        }
                    });

                    parsedData = Papa.unparse(parsedData as UnparseObject);

                    let downloadLink = document.createElement("a");
                    const blob = new Blob(["\ufeff", parsedData]);
                    const url = URL.createObjectURL(blob);

                    downloadLink.href = url;
                    downloadLink.download = "My_Report.csv";

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    this.closeMenu();
                },
            },
        };
        return exportToCsvButton;
    }

    @action
    closeMenu = () => {
        this.menuAnchor = null;
    };

    getCachedColumnConfig = (): Promise<IColDef[] | null> => {
        return new Promise((resolve) => {
            this.localStorage
                .getItem(`${CacheKeyPrefix}-${this.gridId}`)
                .then((value) => {
                    if (value) {
                        let c;
                        if (isString(value)) {
                            c = JSON.parse(value as string);
                        } else {
                            c = value;
                        }
                        resolve(c);
                    } else {
                        resolve(null);
                    }
                });
        });
    };

    @action
    mergeColsWithCache = (inputCols: IColDef[]) => {
        return this.getCachedColumnConfig().then((colsFromCache) => {
            if (colsFromCache) {
                const mergedCols: IColDef[] = [];
                colsFromCache.forEach((cacheCol) => {
                    const inputCol = inputCols.find(
                        (c) => c.headerName === cacheCol.headerName,
                    );
                    if (inputCol) {
                        inputCol.width = cacheCol.width;
                        inputCol.hide = cacheCol.hide;
                        delete inputCol.flex;
                        mergedCols.push(inputCol);
                    }
                });

                // handle columns that dont exist in cache yet
                inputCols.forEach((c) => {
                    if (
                        !colsFromCache
                            .map((cc) => cc.headerName)
                            .includes(c.headerName)
                    ) {
                        mergedCols.push(c);
                    }
                });
                return mergedCols;
            }

            return inputCols;
        });
    };

    updateLocalStorage = async (c: IColDef[]) => {
        await this.localStorage.setItem(
            `${CacheKeyPrefix}-${this.gridId}`,
            JSON.stringify(c),
        );
    };

    @action
    onColResizeOrReorder = _.debounce(async (cols: GridColDef[]) => {
        if (this.disableLocalConfig) {
            return;
        }

        this.columns = cols;

        await this.updateLocalStorage(cols);
    }, 200);

    @action
    reset = () => {
        this.clearSelected();
        this.rows.splice(0, this.rows.length);
    };

    @action
    handleSelectionChange = async (param: string[] | GridRowId[]) => {
        this.gridApi?.current?.setRowSelectionModel(param);
        this.changeSelectedRowIds(param);

        if (this.onChange) {
            await this.onChange();
        }
    };

    @action
    clearSelected = () => {
        this.gridApi?.current?.setRowSelectionModel?.([]);
    };

    /**
     * does not trigger onChange handler
     * @param id
     */
    @action
    removeSelectedById = (id) => {
        const survivors = this.selectedRowIds.filter((rowId) => rowId !== id);
        this.gridApi?.current?.setRowSelectionModel(survivors);
        this.changeSelectedRowIds(survivors);
    };

    @action
    removeSelectedByIdWithVisibilityOverride = (id) => {
        const survivors = this.selectedRowIds.filter((rowId) => rowId !== id);
        this.selectedRowIds.remove(id);
        const r = this.selectedRows.find((r) => r.id === id);
        if (r) {
            this.selectedRows.remove(r);
        }

        this.gridApi?.current?.setRowSelectionModel(survivors);
    };

    // This is an override function for selected row removal
    // meaning it does not matter if the rows are visible,
    // everything gets cleared
    @action
    removeAllSelectedRows = () => {
        this.selectedRowIds.replace([]);
        this.selectedRows.replace([]);
        this.gridApi?.current?.setRowSelectionModel([]);
    };

    // If calling this function, the value is not observable so no reactivity will be associated,
    // meaning don't rely on this go get updated select values
    // this is still used in several places
    // needs to be switched to the observable prop SelectRows
    // Josh Gayso
    getSelectedRows() {
        return Array.from(
            this.gridApi?.current?.getSelectedRows().values() ?? [],
        );
    }

    @action
    showHideColumns = () => {
        this.hideColumnsIsOpen = true;
    };

    @action
    setLoading = (val: boolean) => {
        this.isLoading = val;
    };

    @action
    private changeSelectedRowIds = (selectedIds: string[] | GridRowId[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [newlyAdded, intersection, allRemovedRows] =
            symmetricDifferenceBy(
                this.selectedRowIds,
                selectedIds,
                (arg) => arg,
                (arg) => arg,
            );

        // selectedAndVisibleRemovedRows is here to limit the number of selected rows get removed
        // If a user has searched in the data grid and previously selected row is no longer visible,
        // this forEach statement will ensure that the previosuly selected row will remain selected.
        const selectedAndVisibleRemovedRows = new Set<GridRowId>();
        allRemovedRows.forEach((removedVal) => {
            if (this.rowIds.includes(removedVal.valueOf())) {
                selectedAndVisibleRemovedRows.add(removedVal);
            }
        });

        newlyAdded.forEach((val) => {
            this.selectedRowIds.push(val);
            const r = this.rows.find((r) => r.id === val);
            if (r) {
                this.selectedRows.push(r);
            }
        });

        selectedAndVisibleRemovedRows.forEach((val) => {
            this.selectedRowIds.remove(val);
            const r = this.selectedRows.find((r) => r.id === val);
            if (r) {
                this.selectedRows.remove(r);
            }
        });

        if (
            newlyAdded.size === 0 &&
            selectedAndVisibleRemovedRows.size === 0 &&
            this.gridApi?.current.getSelectedRows.length ===
                this.selectedRowIds.length
        ) {
            return;
        }

        // For some reason, when we choose to clear the grid, selectedRowIds is not zero
        // Have to fix styling of entries after search finishes
        if (this.selectedRowIds.length === 0) {
            this.gridApi?.current.setRowSelectionModel([]);
            return;
        }

        if (!this.gridApi?.current) {
            return;
        }

        this.gridApi?.current.setRowSelectionModel(this.selectedRowIds);
    };

    @computed
    get visibilityModel(): GridColumnVisibilityModel {
        return Object.fromEntries(
            this.columns
                .filter(({ hide }) => hide)
                .map((column) => [column.field, false]),
        );
    }
}

export default AcxDataGridStore;

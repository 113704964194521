import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { SignalReportDatum } from "models/Reporting/ReportDatum";
import { ApplicationFilters } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { SafetyEvent } from "stores/ApplicationFilters/Filters/SafetyEvent";

// Eddy Report Ids
export const EEOverTimeID = "EddyEffectSignalsOverTime";
export const EEByClassifierID = "EddyEffectSignalsByClassifier";
export const EEByClassifiersOverTimeID = "EddyEffectByClassifiersOverTime";
export const AverageScoreByQuestionID = "AverageScoreByQuestion";
export const AverageScoreByQuestionAndEddyOverTimeID =
    "AverageScoreByQuestionAndEddyOverTime";
export const EEPrevalenceByQuestionOverTimeID =
    "EddyEffectPrevalenceByQuestionResponseOverTime";
export const EEPrevalenceByQuestionID = "EddyEffectPrevalenceByQuestion";
export const ClassifierAndEddyID = "ClassifierPrevalenceAndEddyEffect";

// Need these for initial render of nav links, TODO: refactor
export const EEOverTimechartTitle = "Eddy Effect Prevalence Over Time";
export const EEByClassifierchartTitle = "Eddy Effect Prevalence By Classifier";
export const EEByClassifiersOverTimechartTitle =
    "Eddy Effect by Classifiers Over Time";
export const AverageScoreByQuestionchartTitle =
    "Average Score by Question and Eddy";
export const AverageScoreByQuestionAndEddyOverTimechartTitle =
    "Average Score by Question and Eddy Over Time";
export const EEPrevalenceByQuestionOverTimechartTitle =
    "Eddy Effect Prevalence By Question Response Over Time";
export const EEPrevalenceByQuestionchartTitle =
    "Eddy Effect Prevalence By Question Response";
export const ClassifierAndEddychartTitle =
    "Classifier Prevalence and Eddy Effect";

//Trends
export const ClassifierOverTimeName = "Classifier Prevalence Over Time";
export const HipaaCompliancePrevalenceOverTimeName =
    "HIPAA Compliance Prevalence Over Time";

export const ClassifierOverTimeId = "ClassifierPrevalenceOverTime";
export const HipaaCompliancePrevalenceOverTimeId =
    "HipaaCompliancePrevalenceOverTime";

export const AverageScoreByQuestionOverTimeID =
    "AverageScoreByQuestionOverTime";
export const AverageScoreByQuestionOverTimeName =
    "Average Score By Question Over Time";
export const AverageScoreByModuleOverTimeName =
    "Average Score By Module Over Time";
export const AverageScoreByModuleOverTimeID = "AverageScoreByModuleOverTime";
export const SEPrevalenceOverTimeName = "Safety Event Prevalence Over Time";
export const SEPrevalenceOverTimeID = "SafetyEventPrevalenceOverTime";

//Topics
export const TopicPrevalenceID = "TopicPrevalence";
export const TopicPrevalenceName = "Topic Prevalence";
export const TopicPrevalenceOverTimeID = "TopicPrevalenceOverTime";
export const TopicPrevalenceOverTimeName = "Topic Prevalence Over Time";
export const TopicPrevalenceAndEEID = "TopicPrevalenceAndEddyEffect";
export const TopicPrevalenceAndEEName = "Topic Prevalence And Eddy Effect";

// Contacts

export const ContactTypePrevalenceId = "ContactTypePrevalence";
export const ContactTypePrevalenceName = "Contact Type Prevalence";
export const ContactTypePrevalenceOverTimeId = "ContactTypePrevalenceOverTime";
export const ContactTypePrevalenceOverTimeName =
    "Contact Type Prevalence Over Time";
export const ContactTypePrevalenceAndEEId =
    "ContactTypePrevalenceAndEddyEffect";
export const ContactTypePrevalenceAndEEName =
    "Contact Type Prevalence And Eddy Effect";
export const EddyByContactTypeOverTimeId = "EddyEffectByContactTypeOverTime";
export const EddyByContactTypeOverTimeName =
    "Eddy Effect by Contact Type Over Time";

export const SignalsIdToName = {
    [EEOverTimeID]: EEOverTimechartTitle,
    [ClassifierAndEddyID]: ClassifierAndEddychartTitle,
    [EEByClassifierID]: EEByClassifierchartTitle,
    [EEByClassifiersOverTimeID]: EEByClassifiersOverTimechartTitle,
    [EEPrevalenceByQuestionID]: EEPrevalenceByQuestionchartTitle,
    [EEPrevalenceByQuestionOverTimeID]:
        EEPrevalenceByQuestionOverTimechartTitle,
    [AverageScoreByQuestionID]: AverageScoreByQuestionchartTitle,
    [AverageScoreByQuestionAndEddyOverTimeID]:
        AverageScoreByQuestionAndEddyOverTimechartTitle,
    [ClassifierOverTimeId]: ClassifierOverTimeName,
    [AverageScoreByModuleOverTimeID]: AverageScoreByModuleOverTimeName,
    [HipaaCompliancePrevalenceOverTimeId]:
        HipaaCompliancePrevalenceOverTimeName,
    [AverageScoreByQuestionOverTimeID]: AverageScoreByQuestionOverTimeName,
    [TopicPrevalenceID]: TopicPrevalenceName,
    [TopicPrevalenceOverTimeID]: TopicPrevalenceOverTimeName,
    [SEPrevalenceOverTimeID]: SEPrevalenceOverTimeName,
    [TopicPrevalenceAndEEID]: TopicPrevalenceAndEEName,
    [ContactTypePrevalenceId]: ContactTypePrevalenceName,
    [ContactTypePrevalenceOverTimeId]: ContactTypePrevalenceOverTimeName,
    [ContactTypePrevalenceAndEEId]: ContactTypePrevalenceAndEEName,
    [EddyByContactTypeOverTimeId]: EddyByContactTypeOverTimeName,
};

export const EddyReportDataEndpoints = [
    EEOverTimeID,
    ClassifierAndEddyID,
    EEByClassifierID,
    EEByClassifiersOverTimeID,
    EEPrevalenceByQuestionID,
    EEPrevalenceByQuestionOverTimeID,
    AverageScoreByQuestionID,
    AverageScoreByQuestionAndEddyOverTimeID,
] as const;

export const TrendsDataEndpoints = [
    ClassifierOverTimeId,
    AverageScoreByModuleOverTimeID,
    AverageScoreByQuestionOverTimeID,
    HipaaCompliancePrevalenceOverTimeId,
    SEPrevalenceOverTimeID,
] as const;

export const TopicsDataEndpoints = [
    TopicPrevalenceID,
    TopicPrevalenceOverTimeID,
    TopicPrevalenceAndEEID,
] as const;

export const ContactsDataEndpoints = [
    ContactTypePrevalenceId,
    ContactTypePrevalenceOverTimeId,
    ContactTypePrevalenceAndEEId,
    EddyByContactTypeOverTimeId,
] as const;

export const AllSignalDataEndpoints = [
    ...EddyReportDataEndpoints,
    ...TrendsDataEndpoints,
    ...TopicsDataEndpoints,
    ...ContactsDataEndpoints,
] as const;

export type SignalDataEndpoint =
    | typeof EddyReportDataEndpoints[number]
    | typeof TrendsDataEndpoints[number]
    | typeof TopicsDataEndpoints[number]
    | typeof ContactsDataEndpoints[number];

export type SignalsRenderMode = "%" | "#";

export interface ISignalsVizDefinition {
    id: string;
    chartTitle: string;
    chartSubtitle: string;
    dataEndpoint: SignalDataEndpoint;
    data?: any[][];
    countData?: any[][];
    percentData?: any[][];
    xTitle: string;
    yTitle: string;

    chartType: SignalsChartType;
    isTemporal: boolean;
    isStackedBar: boolean;

    showComparePrevCheckbox: boolean;
    showPercentToggle: boolean;
    renderMode?: SignalsRenderMode;
    showDataGrid: boolean;
    dataGridStore: AcxDataGridStore;
    selectionType: ISignalsSelectionTypes;
    showSpotlight: boolean;
    shouldReload: boolean;

    // used for HIPAA and SEPrev selection toggle without
    seriesNames?: string[];
    visibleSeries?: string[];

    vizOptions: string;

    isEddyReport?: boolean;
    isTrendReport?: boolean;
    isTopicReport?: boolean;
    isContactReport?: boolean;
    isDashboardReport?: boolean;

    order: number;
    signalsDashboardId: string;
}

export interface ISignalsChartResponse {
    chartTitle: string;
    chartId: SignalDataEndpoint;
    data: SignalReportDatum[];
    xTitle: string;
    yTitle: string;
}

export interface ISignalsDrilldownResponse {
    count: number;
    data: {
        agentFirstName: string;
        agentLastName: string;
        arrivedOn: string;
        audioMetadataId: string;
        callDurationMillis: number;
        classifiers: IClassifiersFromDrilldown[];
        evalType: string;
        evaluationId: string;
        timestamp: string;
        blobUrl: string;
        hierarchy: string;
    }[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
}

export interface IClassifiersFromDrilldown {
    audioMetadataId: string;
    classifierId: string;
    classifierName: string;
    classifierType: string;
    result: string;
    score: number;
}

export type ISignalsSelectionTypes =
    | "Classifiers"
    | "Question"
    | "Questions"
    | "Tags"
    | "Modules"
    | "Topics"
    | "HIPAA"
    | "Safety Event"
    | "Contact Type"
    | undefined;

export type SignalsTemporalLabels =
    | "Daily"
    | "Weekly"
    | "Monthly"
    | "Quarterly";

export type SignalsChartType = "Line" | "VerticalBar" | "HorizontalBar";

interface AISummaryResultFailure {
    successful: false;
    message: string;
}

interface AISummaryResultSuccess {
    successful: true;
    summary: string;
    protectedDataBase64: string;
}

export type AISummaryResult = AISummaryResultFailure | AISummaryResultSuccess;
export interface AISummaryResponse {
    requestId: string;
    result: AISummaryResult;
}

type SignalsBaseRequest = {
    dateReference?: string;
    previousPeriodStartDate?: string;
    previousPeriodEndDate?: string;
    aggregateDataByTemporalGrain?: boolean;
    requestAiSummary?: boolean;
    requestId?: string;
} & ApplicationFilters;

export type SignalsRequest<T = Record<string, unknown>> = T &
    SignalsBaseRequest;

export type SignalsFilters = Omit<
    SignalsBaseRequest,
    | "previousPeriodStartDate"
    | "previousPeriodEndDate"
    | "aggregateDataByTemporalGrain"
    | "requestAiSummary"
    | "requestId"
>;

export type SignalsStatAvgCallDurationRequest = SignalsFilters & {
    eddyPresence: number;
};

// Used for avg call duration card
export enum EddyPresence {
    WithEddy,
    WithoutEddy,
    AllConversations,
}

export type SignalsDomain =
    | "eddy"
    | "trends"
    | "topics"
    | "contacts"
    | "dashboard";

export const getChartTypeFromChartID = (
    dataEndpoint: SignalDataEndpoint,
): SignalsChartType => {
    switch (dataEndpoint) {
        case EEOverTimeID:
            return "Line";
        case EEByClassifierID:
            return "VerticalBar";
        case EEByClassifiersOverTimeID:
            return "Line";
        case EEPrevalenceByQuestionID:
            return "VerticalBar";
        case AverageScoreByQuestionAndEddyOverTimeID:
            return "Line";
        case AverageScoreByQuestionID:
            return "VerticalBar";
        case ClassifierAndEddyID:
            return "VerticalBar";
        case AverageScoreByModuleOverTimeID:
            return "Line";
        case TopicPrevalenceID:
            return "HorizontalBar";
        case SEPrevalenceOverTimeID:
            return "Line";
        case TopicPrevalenceAndEEID:
            return "VerticalBar";
        case ContactTypePrevalenceId:
        case ContactTypePrevalenceAndEEId:
            return "VerticalBar";
        case EddyByContactTypeOverTimeId:
            return "Line";
        default:
            return "Line";
    }
};

export const getComparePrevControls = (
    dataEndpoint: SignalDataEndpoint,
): boolean => {
    return (
        dataEndpoint === EEByClassifierID ||
        dataEndpoint === EEPrevalenceByQuestionID ||
        dataEndpoint === ContactTypePrevalenceId
    );
};

export const getPercentToggleControls = (
    dataEndpoint: SignalDataEndpoint,
): boolean => {
    return (
        dataEndpoint !== AverageScoreByQuestionAndEddyOverTimeID &&
        dataEndpoint !== AverageScoreByQuestionID &&
        dataEndpoint !== ClassifierAndEddyID &&
        dataEndpoint !== AverageScoreByQuestionOverTimeID &&
        dataEndpoint !== AverageScoreByModuleOverTimeID &&
        dataEndpoint !== TopicPrevalenceAndEEID &&
        dataEndpoint !== TopicPrevalenceID &&
        dataEndpoint !== TopicPrevalenceOverTimeID &&
        dataEndpoint !== ContactTypePrevalenceAndEEId
    );
};

export const getDefaultRenderMode = (
    dataEndpoint: SignalDataEndpoint,
): "%" | "#" => {
    switch (dataEndpoint) {
        case ClassifierAndEddyID:
        case TopicPrevalenceAndEEID:
        case TopicPrevalenceID:
        case TopicPrevalenceOverTimeID:
        case ContactTypePrevalenceAndEEId:
            return "#";
        default:
            return "%";
    }
};

export const getDataGridControls = (
    dataEndpoint: SignalDataEndpoint,
): boolean => {
    return (
        dataEndpoint === EEByClassifiersOverTimeID ||
        dataEndpoint === EEPrevalenceByQuestionOverTimeID ||
        dataEndpoint === ClassifierOverTimeId ||
        dataEndpoint === HipaaCompliancePrevalenceOverTimeId ||
        dataEndpoint === AverageScoreByQuestionOverTimeID ||
        dataEndpoint === AverageScoreByModuleOverTimeID ||
        dataEndpoint === SEPrevalenceOverTimeID ||
        dataEndpoint === TopicPrevalenceOverTimeID ||
        dataEndpoint === ContactTypePrevalenceOverTimeId ||
        dataEndpoint === EddyByContactTypeOverTimeId
    );
};

export const getChartSubtitle = (dataEndpoint: SignalDataEndpoint) => {
    switch (dataEndpoint) {
        case EEByClassifierID:
            return "Classifiers are created from human-defined rulesets of words and phrases. Use this graph to see how often an Eddy is identified in conversations that include the classifier.";
        case EEByClassifiersOverTimeID:
            return "Use this graph to see the trend of Eddy prevalence in conversations that include selected classifiers.";
        case AverageScoreByQuestionID:
            return "Use this graph to see whether average score of a question on an evaluation correlates with Eddy presence.";
        case EEPrevalenceByQuestionID:
            return "Use this graph to see how often an Eddy is found when a question is answered different ways on an evaluation.";
        case EEPrevalenceByQuestionOverTimeID:
            return "Use this graph to see trends of how often an Eddy is found when a question is answered different ways on an evaluation.";
        case AverageScoreByQuestionAndEddyOverTimeID:
            return "Use this graph to see the trend of average scores on an evaluation and whether they correlate with Eddy presence.";
        case ClassifierAndEddyID:
            return "Use this graph to see how often classifiers are present in conversations. Then, look at the Eddy Identified bar to see how often Eddies are present in conversations that include the classifier.";
        case ClassifierOverTimeId:
            return "Classifiers are created from human-defined rulesets of words and phrases. Use this graph to see how  selected Classifiers are trending over time.";
        case HipaaCompliancePrevalenceOverTimeId:
            return "Use this graph to see how HIPAA Compliance is trending in conversations over time.";
        case AverageScoreByQuestionOverTimeID:
            return "Use this graph to see how selected question scores are trending over time.";
        case AverageScoreByModuleOverTimeID:
            return "Use this graph to see how selected module scores are trending over time.";
        case TopicPrevalenceID:
            return "Use this graph to see how often key topics are arising in conversations.";
        case SEPrevalenceOverTimeID:
            return "Use this graph to see how often Safety Events are occurring over time and whether they're being acknowledged by the agent.";
        case TopicPrevalenceOverTimeID:
            return "Use this graph to see how selected topics are trending over time.";
        case TopicPrevalenceAndEEID:
            return "Use this graph to see how often topics are present in conversations. Then, look at the Eddy Present bar to see how often Eddies are present in conversations that include the topic.";
        case ContactTypePrevalenceAndEEId:
            return "Use this graph to see which Contact types exist across your conversations. Then, look at the Eddy Present bar to see how often Eddies are present in your conversations per Contact type.";
        case EddyByContactTypeOverTimeId:
            return "Use this graph to see the trend of Eddy prevalence in conversations by Contact type.";
        default:
            return "";
    }
};

export const getSelectionType = (
    dataEndpoint: SignalDataEndpoint,
): ISignalsSelectionTypes => {
    switch (dataEndpoint) {
        case ClassifierAndEddyID:
        case EEByClassifierID:
        case EEByClassifiersOverTimeID:
        case ClassifierOverTimeId:
            return "Classifiers";
        case EEPrevalenceByQuestionID:
        case EEPrevalenceByQuestionOverTimeID:
            return "Tags";
        case AverageScoreByQuestionID:
        case AverageScoreByQuestionOverTimeID:
            return "Questions";
        case AverageScoreByQuestionAndEddyOverTimeID:
            return "Question";
        case AverageScoreByModuleOverTimeID:
            return "Modules";
        case EEOverTimeID:
            return undefined;
        case TopicPrevalenceID:
        case TopicPrevalenceOverTimeID:
        case TopicPrevalenceAndEEID:
            return "Topics";
        case SEPrevalenceOverTimeID:
            return "Safety Event";
        case HipaaCompliancePrevalenceOverTimeId:
            return "HIPAA";
        case ContactTypePrevalenceId:
        case ContactTypePrevalenceOverTimeId:
        case ContactTypePrevalenceAndEEId:
        case EddyByContactTypeOverTimeId:
            return "Contact Type";
        default:
            return undefined;
    }
};

export const getShowSpotlight = (dataEndpoint: SignalDataEndpoint): boolean => {
    switch (dataEndpoint) {
        case ClassifierAndEddyID:
        case EEPrevalenceByQuestionID:
        case EEPrevalenceByQuestionOverTimeID:
        case EEOverTimeID:
        case EEByClassifierID:
        case EEByClassifiersOverTimeID:
        case AverageScoreByQuestionID:
        case ClassifierOverTimeId:
        case AverageScoreByQuestionAndEddyOverTimeID:
        case HipaaCompliancePrevalenceOverTimeId:
        case AverageScoreByModuleOverTimeID:
        case AverageScoreByQuestionOverTimeID:
        case SEPrevalenceOverTimeID:
        case TopicPrevalenceID:
        case TopicPrevalenceOverTimeID:
        case TopicPrevalenceAndEEID:
        case ContactTypePrevalenceOverTimeId:
        case ContactTypePrevalenceAndEEId:
        case ContactTypePrevalenceId:
        case EddyByContactTypeOverTimeId:
            return true;
        default:
            return false;
    }
};

export const getIsTemporal = (dataEndpoint: SignalDataEndpoint) => {
    if (
        dataEndpoint === EEOverTimeID ||
        dataEndpoint === EEByClassifiersOverTimeID ||
        dataEndpoint === AverageScoreByQuestionAndEddyOverTimeID ||
        dataEndpoint === EEPrevalenceByQuestionOverTimeID ||
        dataEndpoint === ClassifierOverTimeId ||
        dataEndpoint === HipaaCompliancePrevalenceOverTimeId ||
        dataEndpoint === AverageScoreByQuestionOverTimeID ||
        dataEndpoint === AverageScoreByModuleOverTimeID ||
        dataEndpoint === SEPrevalenceOverTimeID ||
        dataEndpoint === TopicPrevalenceOverTimeID ||
        dataEndpoint === ContactTypePrevalenceOverTimeId ||
        dataEndpoint === EddyByContactTypeOverTimeId
    ) {
        return true;
    } else {
        return false;
    }
};

export const getIsStackedBar = (dataEndpoint: SignalDataEndpoint) => {
    if (
        dataEndpoint === ClassifierAndEddyID ||
        dataEndpoint === TopicPrevalenceAndEEID ||
        dataEndpoint === ContactTypePrevalenceAndEEId
    ) {
        return true;
    } else {
        return false;
    }
};

export const getIsEddyReport = (dataEndpoint: SignalDataEndpoint) => {
    if (
        (EddyReportDataEndpoints as unknown as SignalDataEndpoint).includes(
            dataEndpoint,
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export const getIsTrendReport = (dataEndpoint: SignalDataEndpoint) => {
    if (
        (TrendsDataEndpoints as unknown as SignalDataEndpoint).includes(
            dataEndpoint,
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export const getIsTopicReport = (dataEndpoint: SignalDataEndpoint) => {
    if (
        (TopicsDataEndpoints as unknown as SignalDataEndpoint).includes(
            dataEndpoint,
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export const getIsContactReport = (dataEndpoint: SignalDataEndpoint) => {
    if (
        (ContactsDataEndpoints as unknown as SignalDataEndpoint).includes(
            dataEndpoint,
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export enum SafetyEventTypes {
    Identified,
    "Not Identified",
    Acknowledged,
    "Not Acknowledged",
}

export const getSafetyEventTypeEnumIndex = (
    value: string | null,
): SafetyEvent | undefined => {
    if (value === null) return;

    switch (value) {
        case "Identified":
            return SafetyEvent.Identified;
        case "Not Identified":
            return SafetyEvent.NotIdentified;
        case "Acknowledged":
            return SafetyEvent.Acknowledged;
        case "Not Acknowledged":
            return SafetyEvent.NotAcknowledged;
        default:
            return;
    }
    // return SafetyEventTypes[value as keyof typeof SafetyEventTypes] ?? null;
};

type PermissionRequirement = {
    name: string;
    action: "View" | "Edit";
};

export function getChartPermissionRequirements(
    chartId: string,
): PermissionRequirement[] {
    if (chartId === HipaaCompliancePrevalenceOverTimeId) {
        return [{ name: "HIPAA Compliance Model", action: "View" }];
    } else if (chartId === SEPrevalenceOverTimeID) {
        return [{ name: "Safety Events Model", action: "View" }];
    } else if (chartId === TopicPrevalenceOverTimeID) {
        // only need this individual chart perm for TopicPrevOverTime because its the only shared topics chart
        return [{ name: "Topics", action: "View" }];
    }

    return [];
}

export interface SignalsTopics {
    id: string;
    topicLabel: string;
    estimatedNumberOfRecords?: number;
    estimatedPercentageOfTotal?: number;
    displayName?: string;
}

export interface ISignalsUserDashboard {
    id: string;
    signalsVizDefinitions: ISignalsVizDefinition[];
    title: string;
    visibility?: IDashboardVisibility;
    userId?: string;
    logoName: string;
    /**
     * To get the actual saved filter, you can find it in
     * ApplicationFiltersStore.savedFilters array
     */
    pinnedApplicationFilterId?: string;
}

export type IDashboardVisibility = "User" | "Organization";

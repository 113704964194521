import {
    Container,
    Divider,
    Grid,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import FileMetaDialog from "components/Admin/AdminUIComponents/MetadataTemplate/FileMetaDialog";
import SoundClipEditor from "components/SoundClipEditor/SoundClipEditor";
import AcxButton, { IAcxButtonProps } from "components/UI/AcxButton";
import ACXCard from "components/UI/ACXCard";
import AcxChip from "components/UI/AcxChip";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxFilePicker from "components/UI/AcxFileSelector/AcxFilePicker";
import AcxHeatMapGrid from "components/UI/AcxHeatMapGrid";
import AcxMainExpansion from "components/UI/AcxMainExpansion";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxStatusSticker from "components/UI/AcxStatusSticker";
import AcxToast from "components/UI/AcxToast";
import AcxTruncate from "components/UI/AcxTruncate";
import AcxCalendarButton from "components/UI/Calendar/AcxCalendarButton";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import AcxDatePicker from "components/UI/Calendar/AcxDatePicker";
import AcxDateRangePopup from "components/UI/Calendar/AcxDateRangePopup";
import Calendar from "components/UI/Calendar/Calendar";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import AcxDateRangeInputSelect from "components/UI/Calendar/DateRange/AcxDateRangeInputSelect";
import AcxStatusCard from "components/UI/Cards/AcxStatusCard";
import NonBossLevelApproveRejectModuleCard from "components/UI/Cards/NonBossLevelApproveRejectModuleCard";
import AcxHierarchyDetailsFab from "components/UI/HierarchyDetailsFab/AcxHierarchyDetailsFab";
import AcxHierarchyDetailsFabStore from "components/UI/HierarchyDetailsFab/AcxHierarchyDetailsFabStore";
import AcxLogoLoader from "components/UI/Loaders/AcxLogoLoader";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectCreatable from "components/UI/Select/BaseSelectComponents/AcxSelectCreatable";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import ChromeTabs from "components/UI/SubTabs/ChromeTabs";
import Gutters from "Layouts/Responsive/Gutters";
import _ from "lodash";
import moment from "moment";
import React from "react";
import AgentService from "services/AgentService";
import { AuthStore } from "stores/AuthStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import NotificationStore from "stores/Notifications/NotificationStore";
import { NotificationType } from "stores/RealTimeMessages/MessageStoreBase";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import Agentv2 from "../../models/Agentv2";
import useStyles from "../../Styles/Styles";
import AcxSankeyDiagram from "../UI/Visualization/AcxSankeyDiagram";
import AcxGridTest from "./AcxGridTest";
import RecursiveTreeTestComponent from "./AcxRecursiveTreeTest/RecursiveTreeTestComponent";
import AcxTableTest from "./AcxTableTest";
import AcxTestDgStore from "./AcxTestDgStore";
import AcxTestFilterDgStore from "./AcxTestFilterDgStore";
import SampleKButtons from "./Samples/SampleKButtons";

// tx amd: ae57bf4a-c527-4462-a8d5-4aa9df074415

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(3),
            height: "calc(100%-64px)",
            overflow: "auto",
        },
        h1: {
            width: "100%",
        },
    });

function TestOption(p) {
    console.log(JSON.stringify(p));
    // debugger;

    return (
        <div ref={p.innerRef} {...p.innerProps}>
            <div>{p?.label}</div>
        </div>
    );
}

// const getOptionLabel = (data) => {
//     let lbl = "";
//     if (_.has(data, "fullName")) {
//         if (data?.fullName !== " ") {
//             lbl = data.fullName;
//         } else {
//             lbl = data.agentEmail;
//         }
//         if (data.isActive) {
//             lbl = lbl + " - Active";
//         } else {
//             lbl = lbl + " - Inactive";
//         }
//     } else {
//         lbl = data.label;
//     }
//     return lbl;
// };

function ComponentTestView() {
    const notficationStore = useStore(NotificationStore);
    const authStore = useStore(AuthStore);
    const fabStore = useStore(AcxHierarchyDetailsFabStore);
    const [soundClipOpen, setSoundClipOpen] = React.useState(false);

    const classes = useStyles(styles);
    const theme = useTheme();
    const onAcxCalendarButtonClick = () => {
        alert("AcxCalendarButton clicked");
    };

    const somethingClicked = () => {};
    const [metaDialog, setMetaDialog] = React.useState(false);
    const onMetaButtonClick = () => {
        setMetaDialog(true);
    };

    const onMetaDialogClose = () => {
        setMetaDialog(false);
    };

    const testDgStore = useStore(AcxTestDgStore);
    const testFilterDgStore = useStore(AcxTestFilterDgStore);

    // testFilterDgStore.testDgStore.removeHeight = "100px";

    testFilterDgStore.testDgStore.filterMode = "server";
    testFilterDgStore.testDgStore.pagination = true;
    testFilterDgStore.testDgStore.paginationMode = "server";
    // testFilterDgStore.testDgStore.onPageChange = (pageNum) => {
    //     console.log(pageNum);
    //     return mock(true);
    // };

    // testFilterDgStore.testDgStore.filterRequest = (data) => {
    //     console.log(data);
    //     // Note: with real request we will need to pass the filter data
    //     // example data JSON:
    //     // {
    //     //   "items": [
    //     //     {
    //     //       "id": 95536,
    //     //       "columnField": "evaluationQbId",
    //     //       "operatorValue": "contains",
    //     //       "value": "123"
    //     //     }
    //     //   ],
    //     //   "linkOperator": "and"
    //     // }
    //     return mock(true);
    // };

    // const agentSearch = async (inputValue: string): Promise<Agentv2[]> => {
    //     const a = new AgentService();
    //     const agts = await a.searchAgents(
    //         authStore.orgStore.selectedOrganization?.id,
    //         inputValue,
    //     );
    //     return agts;
    // };

    const getSelectArray = () => {
        const arr: any[] = [];

        const names = [
            "Michael",
            "Chris",
            "John",
            "Toni",
            "Cary",
            "Kiki",
            "Tara",
            "Sarah",
            "Kevin",
            "Amy",
            "Mike",
            "Kev",
            "Johnathon",
        ];

        for (let i = 0; i < names.length; i++) {
            const element = names[i];
            arr.push({
                value: i,
                label: element,
                otherItem: element + "Other",
            });
        }

        return arr;
    };

    const getGroupSelectArray = () => {
        const arr: any[] = [];
        const arr2: any[] = [];
        const names = [
            "Michael",
            "Chris",
            "John",
            "Toni",
            "Cary",
            "Kiki",
            "Tara",
            "Sarah",
            "Kevin",
            "Amy",
            "Mike",
            "Kev",
            "Johnathon",
        ];

        for (let i = 0; i < names.length; i++) {
            const element = names[i];
            if (i < 4) {
                arr.push({ value: i, label: element, parent: "Group 1" });
            } else {
                arr2.push({ value: i, label: element, parent: "Group 2" });
            }
        }

        const ar = [
            { label: "Group 1", options: arr },
            { label: "Group 2", options: arr2 },
        ];

        return ar;
    };

    const onSelectMultiChange = (val) => {
        // debugger;
        console.log(val);
    };

    const creatableOnChange = (val) => {
        console.log(JSON.stringify(val));
    };

    const createOption = (val) => {
        console.log(JSON.stringify(val));
    };

    const handleSelectEdit = async () => {
        // debugger;
        // const obj = await getOrgId();
        // alert("select edit user: " + obj);
    };

    const onAgentCreate = (arg) => {
        // evalStore.openCreateAgent();
    };
    const onAgentSelect = (agent: Agentv2) => {
        // interaction?.setAgent(agent);
    };

    const agentSearch = async (inputValue: string) =>
        new Promise<Agentv2[]>(async (resolve) => {
            const a = new AgentService();
            // const agts = await a.searchAgents(
            //     evalStore.authStore.orgStore.selectedOrganization?.id,
            //     inputValue,
            // );
            const agts = await a.searchAgents(
                authStore.orgStore.selectedOrganization?.id,
                inputValue,
            );
            resolve(agts);
        });

    const getOptionLabel = (data) => {
        let lbl = "";
        if (_.has(data, "fullName")) {
            if (data?.fullName !== " ") {
                lbl = data.fullName;
            } else {
                lbl = data.agentEmail;
            }
            if (data.isActive) {
                lbl = lbl + " - Active";
            } else {
                lbl = lbl + " - Inactive";
            }
        } else {
            lbl = data.label;
        }
        return lbl;
    };

    const getAgentDefault = () => {
        // const d = evalStore.agentList[0] || null;
        // return d;
        return null;
    };

    const buttonColors: IAcxButtonProps["color"][] = [
        "primary",
        "white",
        "error",
    ];

    const buttonVariants: IAcxButtonProps["variant"][] = [
        "contained",
        "outlined",
        "text",
    ];

    const buttonProps: IAcxButtonProps[] = [
        {
            onClick: () => {
                notficationStore.sendNotification({
                    title: "Button test",
                    message: "Work, mmkay",
                    timestamp: moment().toISOString(),
                    type: NotificationType.Info,
                });
            },
            style: { margin: 1 },
        },
        { disabled: true, style: { margin: 1 }, children: "disabled" },
        { loading: true, style: { margin: 1 }, children: "loading" },
    ];

    return (
        <Container className={classes.root} maxWidth={false}>
            {/* TODO: Create container component that manages clip/ amdID and gets transcript
            {/* Finalize minimize functionality */}
            {/* Lift audiobuffer state */}
            {/* <AcxInteraction /> */}
            <Grid item>
                <Gutters height="500px" justifyContent="center">
                    <AcxDataGrid
                        dataGridStore={testFilterDgStore.testDgStore}
                    />
                </Gutters>
            </Grid>
            <Grid item>
                <Gutters justifyContent="center" height="300px">
                    <AcxDataGrid dataGridStore={testDgStore.testDgStore} />
                </Gutters>
            </Grid>
            <Grid item>
                <AcxButton
                    fullWidth
                    onClick={() => setSoundClipOpen((prev) => !prev)}
                >
                    Open
                </AcxButton>
            </Grid>
            {soundClipOpen
                ? testDgStore.testDgStore
                      .getSelectedRows()
                      .map((clip: any, index) => {
                          console.log(clip);
                          return (
                              <Grid item key={"montage-clip-grid-" + clip.id}>
                                  {/* <AcxMicroPlayer
                                              // controlButtonClass={colorForIndex}
                                              // isLoading={store.getTaskLoading(
                                              //     `${createAudioClipTask}-${soundClip?.clipIdentifier}`,
                                              // )}
                                              getFunction={() => {
                                                  if (
                                                      clip?.segmentChunks &&
                                                      clip.segmentChunks
                                                          .length > 0
                                                  ) {
                                                      return Promise.resolve(
                                                          clip.segmentChunks[0],
                                                      );
                                                  }
                                                  return downloadClipFromBlob(
                                                      clip?.filePath,
                                                  );
                                              }}
                                          /> */}
                                  <SoundClipEditor
                                      //   fullControls={
                                      //       props.canSeeTranscript
                                      //           ? soundEditorOpen
                                      //           : props.store
                                      //                 .isMediaViewerDockOpen
                                      //   }
                                      noSegmentRemoval
                                      //   onSegmentMouseOver={
                                      //       props.store.setActiveClip
                                      //   }
                                      //   alternateClipDownloader={
                                      //       props.store
                                      //           .redactedClipDownloader
                                      //   }
                                      //   onClipEditorReady={
                                      //       props.store
                                      //           .setAudioVisualizerReady
                                      //   }
                                      //   segmentList={
                                      //       props.store
                                      //           .clipsToSegmentList as any
                                      //   }
                                      //   disabled={
                                      //       !Boolean(
                                      //           props.store.evalAudio,
                                      //       )
                                      //   }
                                      //   onAudioBufferReady={
                                      //       props.store.setEvalAudioBuffer
                                      //   }
                                      id={"eval-audio-interaction-editor"}
                                      generateMp3={false}
                                      //   editorHeight={editorHeight}
                                      //   onClipGenerated={
                                      //       props.store.onAudioClipCreated
                                      //   }
                                      clip={clip}
                                      enableZoom
                                  />
                              </Grid>
                          );
                      })
                : null}

            <Grid container item xs={12} spacing={1}>
                <h1 className={classes.h1}>AcxHierarchyDetailsFab</h1>

                <Grid item>
                    <AcxHierarchyDetailsFab
                        store={fabStore}
                        orgId={authStore.orgStore.selectedOrganization?.id}
                        userId={authStore._user.profile.sub}
                        fabLabel="Custom Fab Label"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
                <h1 className={classes.h1}>AcxRecursiveTree</h1>

                <Grid item>
                    <RecursiveTreeTestComponent />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
                <h1 className={classes.h1}>AcxStatusCard</h1>

                <Grid item xs={3}>
                    <AcxStatusCard
                        containerStyles={{
                            marginRight: "0.25rem",
                            height: "100%",
                        }}
                        primaryContent={
                            <Typography variant="h2">92.4%</Typography>
                        }
                        caption="Overall Quality Score"
                        captionVariant="caption"
                        fluctuationIsPositive={true}
                        fluctuationUnit="%"
                        fluctuationAmount={8.7}
                    />
                </Grid>

                <Grid item xs={3}>
                    <AcxStatusCard
                        containerStyles={{
                            margin: "0 0.25rem",
                            height: "100%",
                        }}
                        primaryContent={
                            <Typography variant="h2">8.4 mins</Typography>
                        }
                        caption="Average Call Length"
                        captionVariant="caption"
                        fluctuationIsPositive={false}
                        fluctuationUnit="min"
                        fluctuationAmount={2.3}
                    />
                </Grid>

                <Grid item xs={3}>
                    <AcxStatusCard
                        containerStyles={{
                            margin: "0 0.25rem",
                            height: "100%",
                        }}
                        primaryContent={
                            <Typography
                                variant="h2"
                                style={{ color: theme.palette.red.main }}
                            >
                                $129,338
                            </Typography>
                        }
                        caption="Estimated Eddy Cost"
                        captionVariant="caption"
                    />
                </Grid>

                <Grid item xs={3}>
                    <AcxStatusCard
                        isLoading={true}
                        containerStyles={{
                            margin: "0 0.25rem",
                            height: "100%",
                        }}
                        primaryContent={
                            <Typography
                                variant="h2"
                                style={{ color: theme.palette.red.main }}
                            >
                                $129,338
                            </Typography>
                        }
                        caption="Estimated Eddy Cost"
                        captionVariant="caption"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid container>
                    <h1 className={classes.h1}>AcxStatusSticker</h1>
                    <AcxStatusSticker
                        title="Custom Title"
                        customRootStyles={{ width: "max-content" }}
                    />

                    <AcxStatusSticker
                        customRootStyles={{ width: "max-content" }}
                    />
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>
                        Structured Nesting - NonBossLevelApproveRejectModuleCard
                    </h1>
                    <Grid item xs={3}>
                        <NonBossLevelApproveRejectModuleCard
                            title="Unhandled Example"
                            level={2}
                            isOpen
                            reviewerNotes="Toggled open with isOpen prop."
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <NonBossLevelApproveRejectModuleCard
                            title="Approval Example"
                            reviewed="approve"
                            reviewerName="Parker Catalano"
                            verifiedDate={new Date().toDateString()}
                            level={1}
                            lineClamp={1}
                            subContent="Maecenas ipsum sem, bibendum ac leo vitae, pulvinar maximus massa. Proin porttitor euismod justo, a tristique dolor blandit sodales. Fusce eu placerat dolor. In quis scelerisque nulla. Nullam luctus cursus pharetra. Nulla eget massa ac nulla viverra tincidunt."
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <NonBossLevelApproveRejectModuleCard
                            title="Rejected Example"
                            reviewed="reject"
                            reviewerName="Parker Catalano"
                            verifiedDate={new Date().toDateString()}
                            level={2}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <NonBossLevelApproveRejectModuleCard
                            title="Completely Custom Card"
                            level={3}
                            lineClamp={1}
                            subContent="Maecenas ipsum sem, bibendum ac leo vitae, pulvinar maximus massa. Proin porttitor euismod justo, a tristique dolor blandit sodales. Fusce eu placerat dolor. In quis scelerisque nulla. Nullam luctus cursus pharetra. Nulla eget massa ac nulla viverra tincidunt."
                            customSubTitle="Custom Sub Title"
                            customCollapseContent={
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus risus, laoreet ultricies risus in, blandit tempor metus. Donec scelerisque finibus dui sed pretium. Nam at diam feugiat, consectetur turpis sed, convallis leo. Cras a viverra diam, in accumsan diam. In sit amet condimentum diam, non volutpat lacus. Integer ultrices diam non ipsum porttitor, quis eleifend purus pellentesque. Morbi mollis sed leo at pretium. Fusce interdum leo nec ullamcorper vulputate."
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <h1 className={classes.h1}>
                        Unstructured Nesting -
                        NonBossLevelApproveRejectModuleCard
                    </h1>
                    <NonBossLevelApproveRejectModuleCard
                        title="Unhandled Example"
                        level={2}
                    />

                    <NonBossLevelApproveRejectModuleCard
                        title="Approval Example"
                        reviewed="approve"
                        reviewerName="Parker Catalano"
                        verifiedDate={new Date().toDateString()}
                        level={1}
                        lineClamp={1}
                        subContent="Maecenas ipsum sem, bibendum ac leo vitae, pulvinar maximus massa. Proin porttitor euismod justo, a tristique dolor blandit sodales. Fusce eu placerat dolor. In quis scelerisque nulla. Nullam luctus cursus pharetra. Nulla eget massa ac nulla viverra tincidunt."
                    />

                    <NonBossLevelApproveRejectModuleCard
                        title="Completely Custom Card"
                        level={3}
                        lineClamp={1}
                        subContent="Maecenas ipsum sem, bibendum ac leo vitae, pulvinar maximus massa. Proin porttitor euismod justo, a tristique dolor blandit sodales. Fusce eu placerat dolor. In quis scelerisque nulla. Nullam luctus cursus pharetra. Nulla eget massa ac nulla viverra tincidunt."
                        customSubTitle="Custom Sub Title"
                        customCollapseContent={
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus risus, laoreet ultricies risus in, blandit tempor metus. Donec scelerisque finibus dui sed pretium. Nam at diam feugiat, consectetur turpis sed, convallis leo. Cras a viverra diam, in accumsan diam. In sit amet condimentum diam, non volutpat lacus. Integer ultrices diam non ipsum porttitor, quis eleifend purus pellentesque. Morbi mollis sed leo at pretium. Fusce interdum leo nec ullamcorper vulputate."
                        }
                    />
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <h1 className={classes.h1}>AcxTruncate</h1>
                        <AcxTruncate
                            lineClamp={1}
                            content={
                                <p>
                                    Tempor ut in aliqua labore velit aliquip
                                    pariatur laboris. Nulla proident laboris
                                    elit ex cillum ut aliquip adipisicing
                                    excepteur laboris non ex labore. Sint
                                    incididunt Lorem exercitation amet sint nisi
                                    culpa ex sunt. Incididunt aute laboris esse
                                    ullamco incididunt velit excepteur veniam.
                                    Elit dolore magna esse laboris irure do non
                                    quis est esse id. Eu officia cupidatat
                                    dolore sint non est qui mollit laborum.
                                </p>
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} style={{ height: "100px" }}>
                        <h1 className={classes.h1}>AcxProgress</h1>
                        {/* <AcxProgress
                            percentComplete={"65"}
                            backgroundColor={theme.palette.primary.main}
                            bottomLabel="65 of 100"
                        /> */}
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} style={{ height: "100px" }}>
                        <h1 className={classes.h1}>AcxLogoLoader</h1>
                        <AcxLogoLoader height={64} isSpinning={false} />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} style={{ height: "600px" }}>
                        <h1 className={classes.h1}>AcxGridTest</h1>
                        <AcxGridTest />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <h1 className={classes.h1}>AcxDateRangeInput</h1>
                        <AcxDateRangeInput
                            labelText="Select date range"
                            onSelect={(startDate, endDate) =>
                                alert("date range input click")
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <h1 className={classes.h1}>AcxDateRangeInputSelect</h1>
                        <AcxDateRangeInputSelect
                            datePickerStore={new DatePickerComponentStore()}
                            dateReferenceOptions={[
                                DateReferenceOption.ArrivalDate,
                                DateReferenceOption.InteractionDate,
                            ]}
                            label="Select date range"
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>AcxMainTextField</h1>
                    <Grid item>
                        <AcxMainTextField
                            value="test main text field"
                            id="test-text-2"
                            placeholderText="Enter something"
                            labelText="Enter something"
                            error={true}
                            helperText="Some help for you"
                            infoText="An informative tooltip that tells you what this input box is used for"
                        />
                    </Grid>

                    <Grid item>
                        <AcxMainTextField
                            value="test main text field"
                            id="test-text-3-help"
                            placeholderText="Enter something"
                            labelText="Enter something"
                            error={true}
                            helperText="Some help for you"
                            helpPopperText={
                                <div
                                    style={{
                                        maxWidth: "300px",
                                        padding: theme.spacing(1),
                                    }}
                                >
                                    <Typography variant="body2">
                                        Examples:
                                    </Typography>
                                    <Typography variant="h3">
                                        20200518 (yyyyMMdd)
                                        <br />
                                        2020-05-18 (yyyy-MM-dd)
                                        <br />
                                        20200518083047 (yyyyMMddhhmmss)
                                        <br />
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body2">
                                        Details:
                                    </Typography>
                                    <Typography variant="h3">
                                        Year
                                        <br />
                                        yy 20
                                        <br />
                                        yyyy 2020
                                        <br />
                                    </Typography>
                                </div>
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>ChromeTabs</h1>
                    <Grid
                        item
                        container
                        xs={12}
                        style={{
                            paddingBottom: "0px",
                            backgroundColor: hexToRGB(
                                theme.palette.secondary.main,
                                0.1,
                            ),
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                        alignContent="flex-end"
                    >
                        <ChromeTabs
                            onChange={(newValue) => {
                                alert(newValue);
                            }}
                            tabs={[
                                { label: "First tab" },
                                { label: "Second tab" },
                                { label: "Disabled", disabled: true },
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>SampleKButtons</h1>
                    <Grid item xs={12}>
                        <Paper>
                            <SampleKButtons />
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>AcxButton</h1>
                    <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        alignContent="center"
                    >
                        {buttonColors.map((color) => (
                            <Grid container item xs={3} margin={1}>
                                {buttonVariants.map((variant) => (
                                    <Grid item margin={1}>
                                        {buttonProps.map((props) => (
                                            <AcxButton
                                                fullWidth
                                                color={color}
                                                variant={variant}
                                                {...props}
                                            >
                                                {`Color: "${
                                                    color ?? "default"
                                                }" Variant: "${
                                                    variant ?? "default"
                                                }" ${props.children ?? ""}`}
                                            </AcxButton>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>AcxToast</h1>
                    <Grid item>
                        <AcxToast
                            message={"This is a message"}
                            title="Titles go here"
                            severity="success"
                        />
                        <AcxToast
                            message={"This is a message"}
                            title="Titles go here"
                            severity="info"
                        />
                        <AcxToast
                            message={"This is a message"}
                            title="Titles go here"
                            severity="loading"
                        />
                        <AcxToast
                            message={"This is a message"}
                            title="Titles go here"
                            severity="error"
                        />
                        <AcxToast
                            message={
                                "This is a much longer message that cannot be displayed all on one line"
                            }
                            title="Titles go here"
                            severity="error"
                        />
                        <AcxToast
                            message={"This is a shorter message"}
                            title="This is a much longer title that cannot be displayed all on one line"
                            severity="error"
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>AcxChip</h1>
                    <Grid item>
                        <div>Add Classifiers:</div>
                        <div>
                            <AcxChip
                                label="CS - COVID"
                                color="blue"
                                size="small"
                                onDelete={() => alert("closed")}
                            />
                            <AcxChip
                                label="CS - Pandemic"
                                color="blue"
                                size="small"
                                onDelete={() => alert("closed")}
                            />
                            <AcxChip
                                label="CS - New Normal"
                                color="blue"
                                size="small"
                                onDelete={() => alert("closed")}
                            />
                            <AcxChip
                                label="Add Classifier"
                                color="gray"
                                size="small"
                                icon="plus"
                                clickable={true}
                                onClick={() => alert("add new")}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <h1 className={classes.h1}>AcxCard</h1>
                    <Grid item>
                        <ACXCard
                            title="2nd Initiative"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2} direction="column">
                    <Grid item>
                        <h1 className={classes.h1}>Calendar</h1>
                        <Calendar
                            onSelection={(dt) => {
                                alert(dt.format());
                            }}
                        ></Calendar>
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxCalendarButton</h1>
                        <AcxCalendarButton onClick={onAcxCalendarButtonClick} />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxDatePicker</h1>
                        <AcxDatePicker
                            onCalendarClick={somethingClicked}
                            onDateChange={(dt) => console.log(dt)}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxCalendarPopup</h1>
                        <AcxCalendarPopup
                            id={"test-calendar"}
                            onSelect={(date) => alert(date)}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxDateRangePopup</h1>
                        <AcxDateRangePopup
                            id={"test-daterange"}
                            defaultStartDate={moment().subtract(30, "days")}
                            defaultEndDate={moment()}
                            onSelect={(startDate, endDate) =>
                                alert(
                                    startDate.format("M/D/YYYY") +
                                        " - " +
                                        endDate.format("M/D/YYYY"),
                                )
                            }
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxMainExpansion</h1>
                        <AcxMainExpansion
                            header={<>Expansion Header</>}
                            expanded={false}
                            body={<>Some test content, bruh</>}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectSingleAsync</h1>
                        <AcxSelectSingleAsync
                            inputLabel="Agent"
                            required={true}
                            fullWidth
                            defaultValue={getAgentDefault()}
                            // defaultOptions={evalStore.agentList}
                            onCreate={onAgentCreate}
                            loadOptions={agentSearch}
                            cacheOptions
                            isLoading={false}
                            isClearable={true}
                            onChange={onAgentSelect}
                            enableNoSelection={true}
                            valueField="id"
                            labelField={getOptionLabel}
                            id={"test-select-async-1"}
                            noOptionMessage={
                                "Please enter First, Last, Agent Code, or Email"
                            }
                        />
                        {/* <AcxSelectSingleAsync
                            // onCreate={(arg) =>
                            //     alert("create the record for: " + arg)
                            // }
                            loadOptions={agentSearch}
                            cacheOptions
                            isLoading={false}
                            isClearable={true}
                            editIcon={true}
                            onEditClick={handleSelectEdit}
                            enableNoSelection={true}
                            // defaultValue={getSelectArray()[6]}
                            valueField="id"
                            // labelField={["fullName", "agentEmail"]}
                            labelField={getOptionLabel}
                            id={"test-select-async-1"}
                        /> */}
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelect</h1>
                        <AcxSelect
                            optionComponent={TestOption}
                            isLoading={false}
                            isClearable={true}
                            editIcon={true}
                            onEditClick={handleSelectEdit}
                            enableNoSelection={true}
                            defaultValue={getSelectArray()[6]}
                            valueField="value"
                            labelField="label"
                            id={"test-selection-1"}
                            options={getSelectArray()}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectSingle</h1>
                        <AcxSelectSingle
                            isLoading={false}
                            isClearable={true}
                            editIcon={true}
                            onEditClick={handleSelectEdit}
                            enableNoSelection={true}
                            defaultValue={getSelectArray()[6]}
                            valueField="value"
                            labelField="label"
                            id={"test-selection-1"}
                            options={getSelectArray()}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectSingle - Group</h1>
                        <AcxSelectSingle
                            isLoading={false}
                            isClearable={true}
                            editIcon={true}
                            onEditClick={handleSelectEdit}
                            enableNoSelection={true}
                            defaultValue={getSelectArray()[6]}
                            valueField="value"
                            labelField="label"
                            id={"test-group-selection-1"}
                            options={getGroupSelectArray()}
                            groupCollapse={true}
                            //  customFilter={customFilter}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectMulti - Group</h1>
                        <AcxSelectMulti
                            isClearable={true}
                            isLoading={true}
                            defaultValue={getSelectArray()[3]}
                            id={"test-selection-2"}
                            options={getGroupSelectArray()}
                            groupCollapse={true}
                            onChange={onSelectMultiChange}
                            valueField="value"
                            labelField="label"
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectMulti</h1>
                        <AcxSelectMulti
                            isClearable={true}
                            isLoading={true}
                            defaultValue={getSelectArray()[3]}
                            id={"test-selection-2"}
                            options={getSelectArray()}
                            onChange={onSelectMultiChange}
                            valueField="value"
                            labelField="label"
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectMulti</h1>
                        <AcxSelectMulti
                            isClearable={true}
                            defaultValue={getSelectArray()[3]}
                            id={"test-selection-3"}
                            options={getSelectArray()}
                            onChange={onSelectMultiChange}
                            valueField="value"
                            labelField="label"
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectMulti</h1>
                        <AcxSelectMulti
                            isDisabled
                            isClearable={true}
                            defaultValue={getSelectArray()[3]}
                            id={"test-selection-3"}
                            options={getSelectArray()}
                            onChange={onSelectMultiChange}
                            valueField="value"
                            labelField="label"
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSelectCreatable</h1>
                        <AcxSelectCreatable
                            onCreate={createOption}
                            isClearable={true}
                            defaultValue={getSelectArray()[3]}
                            id={"test-selection-3"}
                            options={getSelectArray()}
                            onChange={creatableOnChange}
                            valueField="value"
                            labelField="label"
                        />
                    </Grid>

                    <Grid item>
                        <h1 className={classes.h1}>
                            AcxButton & FileMetaDialog
                        </h1>
                        <AcxButton fullWidth onClick={onMetaButtonClick}>
                            Open Meta Dialog
                        </AcxButton>
                        <FileMetaDialog
                            title="Test meta dialog"
                            open={metaDialog}
                            onCloseClick={onMetaDialogClose}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxFilePicker</h1>
                        <AcxFilePicker
                            name="test-comp-picker"
                            onChange={(f) => {
                                alert(f);
                            }}
                            accept="text/csv"
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxTableTest</h1>
                        <AcxTableTest />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxSankeyDiagram Test</h1>
                        <AcxSankeyDiagram
                            data={[
                                ["Refill Confusion", "Topic C", 5],
                                ["Refill Confusion", "Topic A", 1],
                                ["Refill Confusion", "Topic B", 1],
                                ["Refill Confusion", "Topic D", 1],
                                ["Payment Barriers", "Topic C", 1],
                                ["Payment Barriers", "Topic A", 5],
                                ["Payment Barriers", "Topic D", 1],
                                ["Insurance", "Topic C", 1],
                                ["Insurance", "Topic A", 1],
                                ["Insurance", "Topic B", 5],
                                ["Insurance", "Topic D", 1],
                                ["Claims", "Topic C", 1],
                                ["Claims", "Topic A", 1],
                                ["Claims", "Topic B", 1],
                                ["Claims", "Topic D", 5],
                                ["Topic C", "Escalation", 1],
                                ["Topic C", "Resolution B", 1],
                                ["Topic C", "Eddy", 3],
                                ["Topic A", "Escalation", 3],
                                ["Topic A", "Resolution A", 3],
                                ["Topic A", "Resolution B", 3],
                                ["Topic A", "Eddy", 1],
                                ["Topic B", "Escalation", 1],
                                ["Topic B", "Resolution B", 3],
                                ["Topic B", "Eddy", 1],
                                ["Topic D", "Escalation", 1],
                                ["Topic D", "Resolution B", 2],
                                ["Topic D", "Eddy", 7],
                            ]}
                            // nodeColors={[
                            //     "red",
                            //     "blue",
                            //     "green",
                            //     "orange",
                            //     "purple",
                            //     "teal",
                            //     "black",
                            //     "pink",
                            //     "yellow",
                            //     "crimson",
                            //     "cyan",
                            //     "brown",
                            // ]}
                            // weightLabel={"testing"}
                            // height={500}
                            // labelStyle={{ fontSize: 15 }}
                            // width={1500}
                            // nodePadding={70}
                            // nodeWidth={55}
                            // linkColorMode="gradient"
                            // labelPadding={50}
                            // tooltipTextStyle={{ color: "red", bold: true }}
                            // labelStyle={{ color: "red" }}
                        />
                    </Grid>
                    <Grid item>
                        <h1 className={classes.h1}>AcxHeatMapGrid Test</h1>
                        <AcxHeatMapGrid
                            data={new Array(7)
                                .fill(0)
                                .map(() =>
                                    new Array(12)
                                        .fill(0)
                                        .map(() =>
                                            Math.floor(Math.random() * 300),
                                        ),
                                )}
                            xLabels={new Array(12)
                                .fill(0)
                                .map((_, i) => `${i < 5 ? i + 8 : i - 4}:00`)}
                            yLabels={[
                                "Sun",
                                "Mon",
                                "Tue",
                                "Wed",
                                "Thu",
                                "Fri",
                                "Sat",
                            ]}
                            cellTooltip={(x, y, value) => (
                                <div
                                    title={`${
                                        [
                                            "Sun",
                                            "Mon",
                                            "Tue",
                                            "Wed",
                                            "Thu",
                                            "Fri",
                                            "Sat",
                                        ][x]
                                    }, ${y < 5 ? y + 8 : y - 4}:00 = ${value}`}
                                >
                                    {value}
                                </div>
                            )}
                            xLabelStyle={() => ({
                                fontSize: ".65rem",
                            })}
                            yLabelStyle={() => ({
                                fontSize: ".65rem",
                            })}
                            cellStyle={(_x, _y, ratio) => ({
                                background: `rgb(255, 105, 9, ${ratio})`,
                                fontSize: ".7rem",
                                color: `rgb(0, 0, 0)`,
                                width: "50px",
                            })}
                            cellHeight="2.5rem"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ComponentTestView;

// const soundClipService = new SoundClipService();

// const downloadClipFromBlob = async (filePath) => {
//     if (filePath) {
//         return await soundClipService.downloadAudioClipAsBlob(
//             filePath,
//             StorageAccountUseOptions.Main,
//         );
//     }
//     return new Blob([], { type: "audio/wav" });
// };

// const mock = (success, timeout = 1000) => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             if (success) {
//                 const dater = [
//                     {
//                         createdOn: "2023-03-01T18:41:23.105104",
//                         createdBy: "julie.gallina@authenticx.com",
//                         evaluationQbId: 51029230,
//                         note: null,
//                         segmentName: "Clip 0m:17s - 0m:27s",
//                         transcriptionText:
//                             "number. Is that considered the member id? Hello? To",
//                         endTime: 27.4222119140625,
//                         startTime: 17.6967509765625,
//                         tags: "",
//                         url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
//                         filePath:
//                             "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
//                         blobContainer: "authcx",
//                         id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
//                         agentName: "Liz Leija",
//                         customerType: null,
//                         callDirection: "Inbound",
//                         serviceHierarchy: [
//                             "Smart Predict",
//                             null,
//                             null,
//                             null,
//                             null,
//                         ],
//                     },
//                     {
//                         createdOn: "2023-03-01T19:27:42.720127",
//                         createdBy: "hemalatha.malluvalasa@authcx.com",
//                         evaluationQbId: 51029252,
//                         note: null,
//                         segmentName: "Clip 0m:31s - 0m:40s",
//                         transcriptionText:
//                             "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
//                         endTime: 40.299999899999996,
//                         startTime: 31.686563,
//                         tags: "",
//                         url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
//                         filePath:
//                             "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
//                         blobContainer: "authcx",
//                         id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
//                         agentName: "Sydnee Cobbs",
//                         customerType: "Physician",
//                         callDirection: "Inbound",
//                         serviceHierarchy: [
//                             "SharkWeek",
//                             "Shiver",
//                             "MeTimbers",
//                             null,
//                             null,
//                         ],
//                     },
//                 ];
//                 resolve(dater);
//             } else {
//                 reject({ message: "Error" });
//             }
//         }, timeout);
//     });
// };

import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React, { useEffect, useState } from "react";
import Agentv2 from "models/Agentv2";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { Grid } from "@mui/material";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";

export const AgentChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);

    const [agent, setAgent] = useState<Agentv2 | undefined>();
    const isAgentLoading = applicationFilterValuesStore.getTaskLoading(
        ApplicationFilterValuesStore.Tasks.GET_AGENT,
    );

    useEffect(() => {
        if (!applicationFiltersStore.agentId) return setAgent(undefined);

        applicationFilterValuesStore
            .getAgent(applicationFiltersStore.agentId)
            .then(setAgent);
    }, [applicationFilterValuesStore, applicationFiltersStore.agentId]);

    if (isAgentLoading)
        return (
            <AppliedFilterChip
                disabled={props.disabled}
                isApplicable={props.isApplicable}
            >
                <Grid
                    container
                    direction="row"
                    flexWrap="nowrap"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>Agent:</Grid>
                    <Grid item>
                        <AcxLoadingIndicator size={16} alternate="PuffLoader" />
                    </Grid>
                </Grid>
            </AppliedFilterChip>
        );

    return (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.setAgentId(undefined)}
        >
            {agent?.fullName}
        </AppliedFilterChip>
    );
});

import * as React from "react";
import { ThreadActionType } from "../Models/ThreadAction";
import { SelectHierarchiesAction } from "./SelectHierarchies";
import { SelectTopicsAction } from "./SelectTopics";
import { SelectClassifierAction } from "./SelectClassifier";
import { SelectModulesAction } from "./SelectModules";
import { SelectAllModulesInCategoryAction } from "./SelectAllModulesInCategoryAction";

export interface ActionProps {
    args: any;
}

export const ActionTypeToComponent: Record<
    ThreadActionType,
    React.FunctionComponent<ActionProps>
> = {
    [ThreadActionType.SelectHierarchy]: SelectHierarchiesAction,
    [ThreadActionType.SelectTopic]: SelectTopicsAction,
    [ThreadActionType.SelectClassifier]: SelectClassifierAction,
    [ThreadActionType.SelectModule]: SelectModulesAction,
    [ThreadActionType.SelectAllModulesInCategory]: SelectAllModulesInCategoryAction
};
